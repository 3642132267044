import SaleSVG from "assets/images/template/sale-template.svg"
import StaffScreen from "assets/images/template/staff-screen.png";

export const SaleTemplate = {
    id: 2,
    name: "Bán hàng",
    description: "Nắm bắt chỉ số bán hàng theo thời gian thực, theo dõi hiệu quả doanh số và hiệu suất nhân viên bán hàng.",
    actions: ["preview"],
    icon: <SaleSVG />,
    template: [
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền thu được do bán sản phẩm sau khi trừ toàn bộ các giảm giá. Doanh thu thuần = Doanh thu (-) Giảm giá (-) Giảm giá KHTT",
            "isDropdownMeasure": false,
            "widgetId": "1000000000",
            "supportChartTypes": null,
            "template": {
                "w": 8,
                "h": 3,
                "x": 0,
                "y": 1,
                "i": "1000000000",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền thu được do bán sản phẩm sau khi trừ toàn bộ các giảm giá. Doanh thu thuần = Doanh thu (-) Giảm giá (-) Giảm giá KHTT",
            "isDropdownMeasure": false,
            "widgetId": "1000000027",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 4,
                "y": 0,
                "i": "1000000027",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100745,
            "systemName": "sales_orders_location",
            "description": "Doanh thu thuần phân theo chi nhánh bán",
            "isDropdownMeasure": false,
            "widgetId": "1000000013",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 4,
                "i": "1000000013",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "PaidAmount",
                    "measureField": "PaidAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "LocationBranch",
                    "fieldName": "LocationBranch",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần theo chi nhánh bán",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Tổng số lượng đơn hàng từ tất cả các kênh bán hàng",
            "isDropdownMeasure": false,
            "widgetId": "1000000001",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 4,
                "i": "1000000001",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Lượng đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100738,
            "systemName": "sales_orders_product",
            "description": "Top các sản phẩm có số lượng bán hoặc doanh thu thuần ghi nhận cao nhất. Tính từ tất cả các kênh bán hàng",
            "isDropdownMeasure": true,
            "widgetId": "1000000005",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 7,
                "i": "1000000005",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductId",
                    "fieldName": "ProductId",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "or",
                    "dimension": "saleitemtype",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Phân loại là Sản phẩm",
                            "query": "Sản phẩm",
                            "symbol": "is",
                            "value": "product"
                        }
                    ]
                }
            ],
            "chartType": "product",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Sản phẩm bán chạy",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số đơn hàng được tạo trong kỳ thống kê. Dữ liệu này không bao gồm đơn huỷ",
            "isDropdownMeasure": false,
            "widgetId": "1000000021",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 0,
                "i": "1000000021",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Số đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số lượng đơn hàng trung bình một ngày = Số đơn hàng (/) Số ngày trong kỳ thống kê",
            "isDropdownMeasure": false,
            "widgetId": "1000000033",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 2,
                "y": 0,
                "i": "1000000033",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "AvgCountOrderDate",
                    "measureField": "AvgCountOrderDate",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Số đơn hàng trung bình một ngày",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Thực nhận = Đã thu (-) Hoàn trả",
            "isDropdownMeasure": false,
            "widgetId": "1000000031",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 6,
                "y": 0,
                "i": "1000000031",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Thực nhận",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 101005,
            "systemName": "customers_new_and_return",
            "description": "Doanh thu thuần theo khách mới, khách cũ, khách vãng lai",
            "isDropdownMeasure": false,
            "widgetId": "1000000047",
            "supportChartTypes": [
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 7,
                "i": "1000000047",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "CustomerCountSpecial",
                    "measureField": "CustomerCountSpecial",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                },
                {
                    "measureName": "GrossProfitAmount",
                    "measureField": "GrossProfitAmount",
                    "isDisplay": true,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "customertype",
                    "fieldName": "customertype",
                    "isSelected": true,
                    "isDisplay": true
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "customers",
            "reportName": "Doanh thu thuần theo loại khách hàng",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        }
    ],
    previewUrl: StaffScreen
}