import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from "dayjs/plugin/isBetween";
import updateLocale from 'dayjs/plugin/updateLocale';
import arraySupport from 'dayjs/plugin/arraySupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from "dayjs/plugin/calendar";

dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(arraySupport);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(calendar);

export default dayjs;

export function isEmpty(obj) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export const FlexibleMonthValid = (month, year) => {
    // ! Lấy từ 0 - 11 cho 12 tháng
    let newMonth = parseInt(month);
    let newYear = parseInt(year);

    const isFirst = newMonth - 1 < 0;
    const isLast = newMonth + 1 > 11;

    if (isFirst) {
        return {
            month: 11,
            year: newYear - 1
        }
    }

    else if (isLast) {
        return {
            month: 0,
            year: newYear + 1
        }
    } else {
        return {
            month: newMonth,
            year: newYear
        }
    }
}

export function getClientRect(element: HTMLElement | null) {
    if (!element) {
        return null;
    }

    return element.getBoundingClientRect();
}