import { EffectCallback, Fragment, memo, useEffect, useMemo, useState } from "react";
import { useStore } from "pages/ReportListV2/context/store";
import { useEmbedApp, useTrans } from "@haravan/reactapp";
import { useLocation } from "react-router-dom";
import useHistory from "hooks/useHistory";
import useApp from "hooks/useApp";
import useDebouncedCallback from "hooks/useDebounceCallback";
import useGeneralSettings from "hooks/useGeneralSettings";
import dayjs from "components/Dayjs"

import ModelReportListing from "models/listing";

import HRVInputSearch from "components/InputSearch";
import HPickup from "components/HPickup";
import TableList from "components/TableList";
import Modal from "components/Modal";
import EmptyTemplate from "components/EmptyTemplate";
import { SkeletonTable } from "components/TableSkeleton";
import Avatar from "components/Avatar";
import ReportItem from "../../ReportItem";
import CardLoading from "../../CardLoading";
import { Box, Button, Input } from "@chakra-ui/react";
import UpgradePlanSuggest from "../../UpgradePlanSuggest";
import ModeAdvanceInventory from "../../ModeAdvanceInventory";

import { buildQueryString, isEmpty, slugify, queryString, formatDate, checkReportTabByPackages } from "helpers/helpers";

import TooltipWithTouch from "components/TooltipWithTouch";
import HCheckbox from "components/HCheckbox";
import { DEFAULT_ACTIONS, INVENTORY_METHOD_ADVANCE, actionReport, hasPermission } from "pages/ReportListV2/context/store/constants";

const HModal: any = Modal;

const INVENTORY = 'inventory'

const ReportListAll = (props: any) => {
  const { onClickDetail } = props;
  const { showMessage } = useEmbedApp();
  const [newReportName, setNewReportName] = useState("");
  const [actionId, setActionId] = useState(0);
  const { roles, userId, plan_name } = useApp();

  const { state, dispatch, actions } = useStore();

  const {
    // searchReportName,
    reportTab,
    defaultListAuthors,
    authors,
    isFavorite,
    isOpenPopupChangeName,
    isOpenPopupDelete,
    reportTabSelected,
    isLoadingReportAll,
    reportAll,
    reportsDefault,
    limitFeatures,
    sortValue,
  } = state;

  const t = useTrans();
  const { push } = useHistory();
  const { ReportList, EditReport, Delete } = ModelReportListing();
  const generalSettings = useGeneralSettings();

  const [renderReports, setRenderReports] = useState<any>([]);
  const [searchReport, setSearchReport] = useState("");
  const [searchReportListener, setSearchReportListener] = useState("");
  const location: any = useLocation();
  const [isPush, setIsPush] = useState(false);

  const handleChangeDebounced = (value) => {
    setIsPush(true);
    setSearchReportListener(value)
  };

  const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [searchReport]);

  useEffect(() => {
    if (isPush) {
      let queryParam = buildQueryString({ type: reportTabSelected, isFavorite: isFavorite ? 1 : 0, s: searchReportListener, authors })

      push(`/listing${queryParam}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchReportListener]);

  useEffect(() => {
    if (isPush) {
      dispatch(actions.fetchingReportListAll());
    }

    setTimeout(async () => {
      if (reportAll.length) {
        let result = [...reportAll];
        console.log({ searchReportListener, result })
        // ! Search
        result = result.filter((y) => {
          return slugify(t(y["name"]).toLowerCase()).includes(
            slugify(searchReportListener.toLocaleLowerCase()),
          );
        });

        // ! Yêu thích
        if (isFavorite) {
          result = result.filter((y) => y.isFavourite === isFavorite);
        }

        // ! Tab
        if (reportTabSelected?.length) {
          let newResult: any = [];

          reportTabSelected.forEach((tab) => {
            let filterByTypename = result.filter((y) => y.typeName === tab);

            newResult.push(filterByTypename);
          });

          result = newResult.flat();
        }

        // ! Sắp xếp
        if (!isEmpty(sortValue)) {
          if (sortValue.dimension !== "lastViewed") {
            result = result.sort((a, b) => {
              if (sortValue.direction === "desc") {
                if (
                  slugify(a[sortValue.dimension]).toLowerCase() <
                  slugify(b[sortValue.dimension]).toLowerCase()
                ) {
                  return -1;
                }
                if (
                  slugify(a[sortValue.dimension]).toLowerCase() >
                  slugify(b[sortValue.dimension]).toLowerCase()
                ) {
                  return 1;
                }
              } else {
                if (
                  slugify(a[sortValue.dimension]).toLowerCase() <
                  slugify(b[sortValue.dimension]).toLowerCase()
                ) {
                  return 1;
                }
                if (
                  slugify(a[sortValue.dimension]).toLowerCase() >
                  slugify(b[sortValue.dimension]).toLowerCase()
                ) {
                  return -1;
                }
              }

              return 0;
            });
          } else {
            const getValidData = result.filter((y) => y.lastViewed);
            const getInValidData = result.filter((y) => !y.lastViewed.length);

            getValidData.sort((a, b) => {
              var formattedDateA = dayjs(a.lastViewed, "DD/MM/YYYY");
              var timestampA = Math.abs(formattedDateA.unix());
              var formattedDateB = dayjs(b.lastViewed, "DD/MM/YYYY");
              var timestampB = Math.abs(formattedDateB.unix());

              if (sortValue.direction === "asc") {
                if (timestampA < timestampB) {
                  return -1;
                }
                if (timestampA > timestampB) {
                  return 1;
                }
              } else {
                if (timestampA < timestampB) {
                  return 1;
                }
                if (timestampA > timestampB) {
                  return -1;
                }
              }

              return 0;
            });

            result = [...getValidData, ...getInValidData];
          }
        }

        // ! Người tạo
        if (authors.length) {
          let newResult: any = [];

          authors.forEach((author: number) => {
            let filterByAuthor = result.filter((y) => {
              switch (author) {
                case 1:
                  return parseInt(y.createdUserId) === userId

                case 2:
                  return parseInt(y.createdUserId) === 0

                case 3:
                  return parseInt(y.createdUserId) !== userId && y.createdUserId !== 0

                default:
                  return []
              }
            });

            newResult.push(filterByAuthor)
          })

          result = newResult.flat();
        }

        setRenderReports(result);

        dispatch(actions.fetchingReportListAllError());
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchReportListener,
    isFavorite,
    reportTabSelected,
    sortValue,
    reportsDefault,
    queryString,
    authors,
  ]);

  // ! Listener location with searchParams
  useEffect(() => {
    if (location?.search) {
      const searchParams: any = new URLSearchParams(location.search);

      if (searchParams.has("type")) {
        let getTypeParams = searchParams.get('type')
        if (getTypeParams.length) {
          dispatch(actions.setReportTabSelected(getTypeParams.split(",")));
        }
      } else {
        dispatch(actions.setReportTabSelected([]));
      }

      if (searchParams.has("isFavorite")) {
        let getIsFavoriteParams = searchParams.get('isFavorite')

        dispatch(actions.setFavorites(Boolean(parseInt(getIsFavoriteParams))));
      } else {
        dispatch(actions.setFavorites(false));
      }

      if (searchParams.has("s")) {
        let getSearchParams = searchParams.get('s');
        setSearchReport(getSearchParams)
        setIsPush(false);
        handleChangeDebounced(getSearchParams)
      } else {
        setSearchReport('')
      }

      if (searchParams.has("authors")) {
        let getAuthorsParams = searchParams.get('authors');

        dispatch(actions.setAuthors(getAuthorsParams.split(",").map(y => parseInt(y))));
      } else {
        dispatch(actions.setAuthors([]));
      }
    } else {
      setSearchReport('')
      dispatch(actions.setReportTabSelected([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const useEffectGetReportListByQuery = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, []);
  };

  useEffectGetReportListByQuery(() => {
    const queryString = buildQueryString({});

    getReportListAPI(queryString);
  });

  const getReportListAPI = async (args) => {
    if (!args.length && !reportsDefault.length) {
      dispatch(actions.fetchingReportListDefault());
    }

    dispatch(actions.fetchingReportListAll());

    const { data, isError } = await ReportList(args);

    if (isError) {
    } else {
      if (!args.length && !reportsDefault.length) {
        dispatch(actions.fetchingReportListDefaultSuccess(data));
      }

      setRenderReports(data);
      dispatch(actions.fetchingReportListAllSuccess(data));
    }
  };

  const handleChangeSearch = (value) => {
    setSearchReport(value);
    debouncedCallback(value);
  };

  const toggleFavoriteButton = () => {
    dispatch(actions.setFavorites(!isFavorite));

    let queryParam = buildQueryString({ type: reportTabSelected, isFavorite: !isFavorite ? 1 : 0, s: searchReportListener, authors })
    push(`/listing${queryParam}`)

    setIsPush(true);
  };

  const toggleFavoriteBtnItem = async (id, value) => {
    let newDataForDefault = [...reportAll];
    let newData = [...renderReports];

    const { isError } = await EditReport(id, { isFavourite: !value });

    if (isError) {
      console.log("Please try again!!");
    } else {
      const indexItem = newData.findIndex((item) => item.id === id);
      const indexItemDefault = newDataForDefault.findIndex(
        (item) => item.id === id,
      );

      if (indexItem !== -1) {
        newData[indexItem] = {
          ...newData[indexItem],
          isFavourite: !value,
        };

        newDataForDefault[indexItemDefault] = {
          ...newDataForDefault[indexItemDefault],
          isFavourite: !value,
        };

        showMessage(
          t(`${value ? t("Bỏ yêu thích") : t("Yêu thích")} ${t("thành công")}`),
        );
      } else {
        showMessage(t("Thao tác thất bại!"), 'error');
      }

      if (isFavorite) {
        newData = newData.filter((value) => {
          return value.isFavourite;
        });
      }

      dispatch(actions.fetchingReportListAllSuccess(newDataForDefault));

      setRenderReports(newData);
    }

  };

  const nametag = (tag) => {
    return (
      <div className="hrv-report-card__tag hrv-report-mb-0">
        <span>{t(tag)}</span>
      </div>
    );
  };

  const handleTogglePopupChangeName = () => {
    dispatch(actions.togglePopupChangeName(!isOpenPopupChangeName));
  };

  const handleTogglePopupDelete = () => {
    dispatch(actions.togglePopupDelete(!isOpenPopupDelete));
  };

  const handleSaveNewName = async () => {
    let newDataForDefault = [...reportAll];
    let newData = [...renderReports];

    const { isError } = await EditReport(actionId, { name: newReportName });

    if (isError) {
      console.log("Please try again!!");
    } else {
      const indexItem = newData.findIndex((item) => item.id === actionId);
      const indexItemDefault = newDataForDefault.findIndex(
        (item) => item.id === actionId,
      );

      if (indexItem !== -1) {
        const indexItem = newData.findIndex((y) => y.id === actionId);

        if (indexItem !== -1) {
          newData[indexItem] = { ...newData[indexItem], name: newReportName };
          newDataForDefault[indexItemDefault] = { ...newDataForDefault[indexItemDefault], name: newReportName };
        }

        showMessage(t(`Đổi tên báo cáo thành công`));
      } else {
        showMessage(t("Đổi tên báo cáo thất bại!"), 'error');
      }

      dispatch(actions.fetchingReportListAllSuccess(newDataForDefault));

      setRenderReports(newData);
    }
  };

  const handleDeleteReport = async () => {
    let newDataForDefault = [...reportAll];
    let newData = [...renderReports];

    const { isError } = await Delete(actionId);

    if (isError) {
      console.log("Please try again!!");
    } else {
      const indexItem = newData.findIndex((item) => item.id === actionId);
      const indexItemDefault = newDataForDefault.findIndex((item) => item.id === actionId);

      if (indexItem !== -1) {
        const indexItem = newData.findIndex((y) => y.id === actionId);

        if (indexItem !== -1) {
          newData.splice(indexItem, 1);
          newDataForDefault.splice(indexItemDefault, 1);
        }

        showMessage(t(`Xoá báo cáo thành công`));
      } else {
        showMessage(t("Xoá báo cáo thất bại!"), 'error');
      }

      dispatch(actions.fetchingReportListAllSuccess(newDataForDefault));

      setRenderReports(newData);
    }
  };

  const handleOnClickEditName = (value, id) => {
    setNewReportName(value);
    setActionId(id);
    handleTogglePopupChangeName();
  };

  const handleOnClickDelete = (id) => {
    handleTogglePopupDelete();
    setActionId(id);
  };

  const renderLabelNewReport = () => {
    return (
      <span
        className="hrv-report-ml-4 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-white hrv-report-border-4 hrv-report-fw-400"
        style={{ backgroundColor: "#DC2828" }}
      >
        {t("new")}
      </span>
    );
  };

  // const renderLabelBetaReport = () => {
  //   return (
  //     <span
  //       className="hrv-report-ml-4 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-blue-6 hrv-report-border-4 hrv-report-fw-500"
  //       style={{ backgroundColor: "#E1F3FE" }}
  //     >
  //       {t("Beta")}
  //     </span>
  //   );
  // };

  const dataRenderHeader = [
    {
      dataIndex: "isFavourite",
      label: "",
      render: (isFavorites, { id }) => {
        return <TooltipWithTouch
          key={id}
          hasArrow
          label={
            isFavorites ? (
              <>
                {t("Báo cáo đã yêu thích")}
                <br />
                {t("Nhấn để gỡ khỏi yêu thích")}
              </>
            ) : (
              t("Thêm vào yêu thích")
            )
          }
          placement="top-start"
        >
          <div
            className="hrv-report-d-flex hrv-report-justify-end hrv-report-cursor-pointer"
            onClick={() => {
              toggleFavoriteBtnItem(id, isFavorites);
            }}
          >
            {isFavorites ? svgStarredActive : svgStarredDisable}
          </div>
        </TooltipWithTouch>
      },
      width: "3%",
    },
    {
      dataIndex: "name",
      label: t("Báo cáo"),
      render: (value, item) => {
        return <div
          key={item.id}
          className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between"
          style={{
            overflowX: "hidden",
            width: "600px",
          }}
        >
          <div className="hrv-report-d-flex hrv-report-cursor-pointer">
            <span
              className="hrv-report-d-inline hrv-report-mb-0 hrv-report-fs-14 hrv-report-text-color-blue-5"
              style={{
                maxWidth: `calc(600px - ${88}px - ${item.isAddNewReport ? "70px" : "0px"
                  })`,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                onClickDetail &&
                  onClickDetail(item.typeName, item.id, item.reportNameSystem);
              }}
            >
              {t(value)}
            </span>

            <span>{item.isAddNewReport && renderLabelNewReport()}</span>
          </div>

          <div className="hrv-report-list-function">
            {DEFAULT_ACTIONS.map((interact, idx) => {
              return (
                <Fragment key={`actions_${idx}`}>
                  {!item.isSystemReport &&
                    (hasPermission(roles[0], interact.action) ||
                      userId === item.createdUserId) && (
                      <TooltipWithTouch
                        hasArrow
                        label={t(interact.detail)}
                        placement="bottom"
                      >
                        <span
                          className="hrv-report-mr-8 hrv-report-cursor-pointer"
                          onClick={() => {
                            switch (interact.action) {
                              case actionReport.EDIT_NAME:
                                handleOnClickEditName(value, item.id);

                                break;

                              case actionReport.DELETE:
                                handleOnClickDelete(item.id);

                                break;
                            }
                          }}
                        >
                          {interact.icon}
                        </span>
                      </TooltipWithTouch>
                    )}
                </Fragment>
              );
            })}
          </div>
        </div>
      },
      width: "57%",
      sortable: true,
    },
    {
      dataIndex: "typeName",
      label: t("Danh mục"),
      render: (value, item) => (

        <div className="hrv-report-d-flex hrv-report-items-center" key={item.id}>
          {nametag(value)}
        </div>
      ),
      width: "10%",
      sortable: true,
    },
    {
      dataIndex: "lastViewed",
      label: t("Xem gần nhất"),
      render: (value, item) => (
        <div
          key={item.id}
        >
          {value.length ? (value !== "1970-01-01T17:00:00Z" ? formatDate(value, "DD/MM/YYYY") : "--") : "--"}
        </div>
      ),
      width: "10%",
      sortable: true,
    },
    {
      dataIndex: "createdUser",
      label: t("Người tạo"),
      render: (value, item) => (
        <div key={item.id}>
          <div className="hrv-report-d-flex hrv-report-items-center">
            <Avatar name={value} isSystem={item.isSystemReport} url="" />

            {item.isSystemReport ? (
              <span className="hrv-report-fs-14 hrv-report-text-color-black-6">
                Haravan
              </span>
            ) : (
              <span className="hrv-report-ml-6 hrv-report-fs-14 hrv-report-text-color-black-6">
                {value}
              </span>
            )}
          </div>
        </div>
      ),
      width: "20%",
      sortable: true,
    },
  ];

  const headerPopupChangeName = () => {
    return (
      <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between">
        <Box textStyle="h5">{t("Đổi tên báo cáo")}</Box>

        <span
          style={{
            width: 18,
          }}
          className="hrv-report-cursor-pointer"
          onClick={() => {
            handleTogglePopupChangeName();
          }}
        >
          {svgClose}
        </span>
      </div>
    );
  };

  const bodyContentChangeName = () => {
    return (
      <div className="hrv-report-mb-0">
        <p className="hrv-report-mb-4 hrv-report-fs-14 hrv-report-fw-500">
          {t("Tên báo cáo mới")}
        </p>

        <Input
          variant="autocomplete"
          onChange={(e) => {
            setNewReportName(e.target.value);
          }}
          value={newReportName}
          placeholder={t("Nhập tên báo cáo")}
          size={"m"}
        />
      </div>
    );
  };

  const footerContentChangeName = () => {
    return (
      <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
        <span></span>

        <div className="hrv-report-d-flex">
          <Button variant="default-default" className="hrv-report-mr-8"
            onClick={() => {
              handleTogglePopupChangeName();
            }}>
            {t("cancelLabel")}
          </Button>

          <Button
            variant="default-primary"
            onClick={() => {
              handleSaveNewName();
              handleTogglePopupChangeName();
            }}>
            {t("Lưu")}
          </Button>
        </div>
      </div>
    );
  };

  const headerPopupDelete = () => {
    return (
      <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between">
        <Box textStyle="h5">{t("Xoá báo cáo")}</Box>

        <span
          style={{
            width: 18,
          }}
          className="hrv-report-cursor-pointer"
          onClick={() => {
            handleTogglePopupDelete();
          }}
        >
          {svgClose}
        </span>
      </div>
    );
  };

  const bodyContentDelete = () => {
    return (
      <div className="hrv-report-mb-0">
        <p className="hrv-report-mb-4 hrv-report-fs-14">
          {t("Báo cáo sẽ không thể phục hồi lại, bạn có muốn tiếp tục")}?
        </p>
      </div>
    );
  };

  const footerContentDelete = () => {
    return (
      <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
        <span></span>

        <div className="hrv-report-d-flex">
          <Button
            variant="default-default"
            className="hrv-report-mr-8"
            onClick={() => {
              handleTogglePopupDelete();
            }}
          >
            {t("cancelLabel")}
          </Button>

          <Button
            variant="critical-primary"
            onClick={() => {
              handleDeleteReport();
              handleTogglePopupDelete();
            }}
          >
            {t("Xoá")}
          </Button>
        </div>
      </div>
    );
  };

  const toggleReportTabSelect = (tabName) => {
    let result = [...reportTabSelected];
    const isExistActiveTab = result.findIndex((y) => y === tabName);

    if (result.length && isExistActiveTab !== -1) {
      result = []
    } else {
      result = [tabName]
    }


    let queryParam = "";

    if (result.length) {
      queryParam = buildQueryString({ type: result, isFavorite: isFavorite ? 1 : 0, s: searchReportListener, authors })
    } else {
      queryParam = buildQueryString({ isFavorite: isFavorite ? 1 : 0, s: searchReportListener, authors })
    }

    push(`/listing${queryParam}`)

    dispatch(actions.setReportTabSelected(result));

    setIsPush(true);
  };

  const handleOnClickCheckbox = (value) => {
    dispatch(actions.setAuthors(value));

    let queryParam = buildQueryString({ type: reportTabSelected, isFavorite: isFavorite ? 1 : 0, s: searchReportListener, authors: value });

    push(`/listing${queryParam}`)

    setIsPush(true);
  }

  const handleSortTable = (name) => {
    let orderBy = {
      ...sortValue,
    };

    if (name === sortValue.dimension) {
      orderBy = {
        ...orderBy,
        direction: sortValue.direction === "asc" ? "desc" : "asc",
      };
    } else {
      orderBy = {
        dimension: name,
        direction: sortValue.direction === "asc" ? "desc" : "asc",
      };
    }

    dispatch(actions.setSortValue(orderBy));

    setIsPush(true);
  };

  const { packages, plans } = checkReportTabByPackages(reportTabSelected, plan_name)

  const isModeAdvanceInventory = useMemo(() => {
    return reportTabSelected.includes(INVENTORY) && generalSettings.inventoryMethod !== INVENTORY_METHOD_ADVANCE
  }, [generalSettings, reportTabSelected])

  return (
    <div className="hrv-report-mt-32">
      <Box textStyle="h2"
        px={[5, 5, 0]}
        mb={4}>
        {t("Tất cả báo cáo")}
      </Box>

      <div className="hrv-report-whitebox-no-bs hrv-report-p-0">
        <div className="hrv-report-px-16 hrv-report-pt-16">
          <HRVInputSearch
            placeholder={t("Nhập tên báo cáo bạn muốn tìm")}
            value={searchReport}
            onChange={handleChangeSearch}
            isShowCloseButton
            classNames={"hrv-report-inputsearch_new"}
            prefixIcon={svgSearchIcon}
            size='m'
          />
        </div>

        <div className="hrv-report-px-16 hrv-report-row hrv-report-items-center">
          <div className="hrv-report-mr-8 hrv-report-mt-12">
            <HCheckbox
              isMultiple={true}
              name="name"
              valueName="value"
              defaultValue={authors}
              showButtonAll={false}
              onChange={(value) => {
                handleOnClickCheckbox(value);
              }}
              options={defaultListAuthors}
              useEmptyForGetAll={true}
              nameField={t("Người tạo")}
              isLoading={false}
              flexibleHeight={32}
              multipleType={'tag'}
            />
          </div>

          <HPickup
            classNames="hrv-report-mr-8 hrv-report-mt-12"
            name={t("Yêu thích")}
            isActive={isFavorite}
            iconState={svgStarredDisable}
            iconStateActive={svgStarredActive}
            onClick={toggleFavoriteButton}
          />

          {reportTab.map((tab, idx) => {
            const isHiddenFeature =
              limitFeatures?.findIndex((y) => y === tab.name) !== -1;

            return (
              <HPickup
                classNames={`${isHiddenFeature ? "hrv-report-d-none" : ""
                  } hrv-report-mr-8 hrv-report-mt-12`}
                key={idx}
                name={t(tab.name)}
                isActive={reportTabSelected?.includes(tab.name || "")}
                onClick={() => {
                  toggleReportTabSelect(tab.name);
                }}
                isNew={tab.isNew}
              />
            );
          })}
        </div>

        <div className="hrv-report-py-16">
          {isLoadingReportAll ? (
            <>
              <div className="hrv-report-md-d-block hrv-report-d-none">
                <SkeletonTable dataColumn={5} dataRow={20} />
              </div>

              <div className="hrv-report-md-d-none hrv-report-d-block">
                {Array(10)
                  .fill(0)
                  .map((_, idx) => {
                    return (
                      <div key={idx} className="hrv-report-mt-12">
                        <CardLoading />
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              {
                !!packages.length &&
                  reportTabSelected.every(i => packages.includes(i)) ?
                  <UpgradePlanSuggest packages={packages} plans={plans} />
                  :
                  <>
                    {
                      isModeAdvanceInventory ?
                        <ModeAdvanceInventory /> :
                        <>
                          {!!renderReports && !!renderReports.length ? (
                            <>
                              <div className="hrv-report-md-d-block hrv-report-d-none">
                                <TableList
                                  dataRenderHeader={dataRenderHeader}
                                  dataRenderBody={renderReports}
                                  sortValue={sortValue}
                                  onHandleSortTable={handleSortTable}
                                />
                              </div>

                              <div className="hrv-report-md-d-none hrv-report-d-block">
                                {renderReports.map((report, idx) => {
                                  return (
                                    <ReportItem
                                      {...report}
                                      key={idx}
                                      onToggleFavoriteBtnItem={toggleFavoriteBtnItem}
                                      userId={userId}
                                      roles={roles}
                                      onClickEdit={handleOnClickEditName}
                                      onClickDelete={handleOnClickDelete}
                                      onClickDetail={onClickDetail}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <EmptyTemplate
                              text={t("no_data")}
                              subText={t("Vui lòng thay đổi bộ lọc")}
                            />
                          )}
                        </>
                    }
                  </>
              }
            </>
          )}
        </div>

        <div>
          <HModal
            renderHeader={headerPopupChangeName}
            renderBody={bodyContentChangeName}
            renderFooter={footerContentChangeName}
            onClose={() => {
              dispatch(actions.togglePopupChangeName(false));
            }}
            visible={isOpenPopupChangeName}
            closable={false}
            isCentered
          ></HModal>
        </div>

        <div>
          <HModal
            renderHeader={headerPopupDelete}
            renderBody={bodyContentDelete}
            renderFooter={footerContentDelete}
            onClose={() => {
              dispatch(actions.togglePopupDelete(false));
            }}
            visible={isOpenPopupDelete}
            closable={false}
            isCentered
          ></HModal>
        </div>
      </div>
    </div>
  );
};

export default memo(ReportListAll);

const svgSearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.1296 11.8792H12.4712L12.2379 11.6542C13.0546 10.7042 13.5462 9.47086 13.5462 8.12919C13.5462 5.13752 11.1212 2.71252 8.12956 2.71252C5.13789 2.71252 2.71289 5.13752 2.71289 8.12919C2.71289 11.1209 5.13789 13.5459 8.12956 13.5459C9.47122 13.5459 10.7046 13.0542 11.6546 12.2375L11.8796 12.4709V13.1292L16.0462 17.2875L17.2879 16.0459L13.1296 11.8792ZM8.12956 11.8792C6.05456 11.8792 4.37956 10.2042 4.37956 8.12919C4.37956 6.05419 6.05456 4.37919 8.12956 4.37919C10.2046 4.37919 11.8796 6.05419 11.8796 8.12919C11.8796 10.2042 10.2046 11.8792 8.12956 11.8792Z"
      fill="#4B5563"
    />
  </svg>
);

const svgStarredDisable = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3337 8.11683L12.342 7.60016L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085L15.1503 17.9168L13.792 12.0585L18.3337 8.11683ZM10.0003 13.2502L6.86699 15.1418L7.70033 11.5752L4.93366 9.17516L8.58366 8.8585L10.0003 5.50016L11.4253 8.86683L15.0753 9.1835L12.3087 11.5835L13.142 15.1502L10.0003 13.2502Z"
      fill="#4B5563"
    />
  </svg>
);

const svgStarredActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 14.8085L15.1503 17.9168L13.7837 12.0585L18.3337 8.11683L12.342 7.6085L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085Z"
      fill="#F59F0A"
    />
  </svg>
);

const svgClose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#4B5563"
    />
  </svg>
);
