import StoreSVG from "assets/images/template/store-template.svg"
import ManagerScreen from "assets/images/template/manager-screen.png";

export const StoreManager = {
    id: 1,
    name: "Quản lý cửa hàng",
    description: "Quản lý hoạt động hằng ngày của cửa hàng, theo dõi nhân sự, doanh số và tình hình vận hành tổng quát.",
    actions: ["preview"],
    icon: <StoreSVG />,
    template: [
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền thu được do bán sản phẩm sau khi trừ toàn bộ các giảm giá. Doanh thu thuần = Doanh thu (-) Giảm giá (-) Giảm giá KHTT",
            "isDropdownMeasure": false,
            "supportChartTypes": null,
            "widgetId": "1000000000",
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 2,
                "i": "1000000000",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền thu được do bán sản phẩm sau khi trừ toàn bộ các giảm giá. Doanh thu thuần = Doanh thu (-) Giảm giá (-) Giảm giá KHTT",
            "isDropdownMeasure": false,
            "widgetId": "1000000027",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 1,
                "i": "1000000027",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100741,
            "systemName": "sales_orders_salechannel",
            "description": "Số lượng đơn hàng, doanh thu thuần phân theo kênh bán (bao gồm cả online và offline)",
            "isDropdownMeasure": false,
            "widgetId": "1000000002",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 2,
                "x": 4,
                "y": 3,
                "i": "1000000002",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "SourceName",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "channel",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần - từ kênh bán hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100742,
            "systemName": "sales_orders_salechannel_pos",
            "description": "Doanh thu thuần theo chi nhánh bán",
            "isDropdownMeasure": false,
            "widgetId": "1000000010",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 5,
                "i": "1000000010",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "PaidAmount",
                    "measureField": "PaidAmount",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "LocationBranch",
                    "fieldName": "LocationBranch",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "OrderCreatedUser",
                    "fieldName": "OrderCreatedUser",
                    "isSelected": false,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "UserId",
                    "fieldName": "UserId",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "and",
                    "dimension": "SourceName",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Kênh bán hàng là POS",
                            "query": "pos",
                            "symbol": "is",
                            "value": "pos"
                        }
                    ]
                }
            ],
            "chartType": "column",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần theo chi nhánh bán",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100758,
            "systemName": "sales_orders_product_itemtype",
            "description": "Doanh thu thuần phân theo nhà cung cấp sản phẩm",
            "isDropdownMeasure": false,
            "widgetId": "1000000018",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 8,
                "i": "1000000018",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductVendorName",
                    "fieldName": "ProductVendorName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần theo nhà cung cấp",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền hoàn trả do khách hàng đổi trả hàng",
            "isDropdownMeasure": false,
            "widgetId": "1000000030",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 2,
                "y": 0,
                "i": "1000000030",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Hoàn trả",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Tổng số lượng đơn hàng từ tất cả các kênh bán hàng",
            "isDropdownMeasure": false,
            "widgetId": "1000000001",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 0,
                "i": "1000000001",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Lượng đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100738,
            "systemName": "sales_orders_product",
            "description": "Top các sản phẩm có số lượng bán hoặc doanh thu thuần ghi nhận cao nhất. Tính từ tất cả các kênh bán hàng",
            "isDropdownMeasure": true,
            "widgetId": "1000000005",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 2,
                "x": 4,
                "y": 9,
                "i": "1000000005",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductId",
                    "fieldName": "ProductId",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "or",
                    "dimension": "saleitemtype",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Phân loại là Sản phẩm",
                            "query": "Sản phẩm",
                            "symbol": "is",
                            "value": "product"
                        }
                    ]
                }
            ],
            "chartType": "product",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Sản phẩm bán chạy",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "",
            "isDropdownMeasure": false,
            "widgetId": "1000000021",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 0,
                "i": "1000000021",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Số đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Thực nhận = Đã thu (-) Hoàn trả",
            "isDropdownMeasure": false,
            "widgetId": "1000000031",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 2,
                "y": 1,
                "i": "1000000031",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Thực nhận",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100742,
            "systemName": "sales_orders_salechannel_pos",
            "description": "Top chi nhánh POS có giá trị cao nhất theo doanh thu thuần/ thực nhận hoặc hoàn trả",
            "isDropdownMeasure": true,
            "widgetId": "1000000007",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 2,
                "x": 4,
                "y": 5,
                "i": "1000000007",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "PaidAmount",
                    "measureField": "PaidAmount",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                },
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": true,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "LocationBranch",
                    "fieldName": "LocationBranch",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "and",
                    "dimension": "SourceName",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Kênh bán hàng là POS",
                            "query": "pos",
                            "symbol": "is",
                            "value": "pos"
                        }
                    ]
                }
            ],
            "chartType": "location",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Top chi nhánh POS",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100744,
            "systemName": "sales_orders_staff",
            "description": "Top nhân viên có doanh thu thuần ghi nhận cao nhất",
            "isDropdownMeasure": false,
            "widgetId": "1000000006",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 2,
                "x": 4,
                "y": 7,
                "i": "1000000006",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "OrderCreatedUser",
                    "fieldName": "OrderCreatedUser",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "staff",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Top nhân viên bán hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 101005,
            "systemName": "customers_new_and_return",
            "description": "Tỷ trọng đơn theo phân khúc khách hàng",
            "isDropdownMeasure": false,
            "widgetId": "1000000047",
            "supportChartTypes": [
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 11,
                "i": "1000000047",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "CustomerCountSpecial",
                    "measureField": "CustomerCountSpecial",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": true,
                    "isSelected": true
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "GrossProfitAmount",
                    "measureField": "GrossProfitAmount",
                    "isDisplay": true,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "customertype",
                    "fieldName": "customertype",
                    "isSelected": true,
                    "isDisplay": true
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "customers",
            "reportName": "Tỷ trọng đơn theo phân khúc khách hàng",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 1000045904,
            "systemName": "order_shipments_overview_carriers_internal_speed",
            "description": "Thống kê thời gian xử lý đơn nội bộ phân theo kho/chi nhánh",
            "isDropdownMeasure": false,
            "widgetId": "1000000026",
            "supportChartTypes": [
                "stackedbar",
                "stackedhorizalbar"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 11,
                "i": "1000000026",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderToShipmentTimeSpan",
                    "measureField": "OrderToShipmentTimeSpan",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "ShipmentToHandOverTimeSpan",
                    "measureField": "ShipmentToHandOverTimeSpan",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Day",
                    "fieldName": "Day",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Month",
                    "fieldName": "Month",
                    "isSelected": false,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "DayOfWeek",
                    "fieldName": "DayOfWeek",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "stackedhorizalbar",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "shipments",
            "reportName": "Tốc độ xử lý đơn nội bộ",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 15
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        }
    ],
    previewUrl: ManagerScreen
}