// ! Hooks
import { useEmbedApp } from "@haravan/reactapp";
import useApp from "hooks/useApp";
import useHistory from "hooks/useHistory";
import { useQueryString } from "hooks/useQueryString";
import useRoles from "hooks/useRoles";
import { useTrans } from "locales/hook";
import { memo, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "../../context/store";
import { useDetectPage } from "hooks/useDetectPage";
import useLastestView from "hooks/useLastestView";
import { useSummaryDetail } from "pages/ReportStandard/hooks";
import useFetchDataChart from "hooks/useFetchDataChart";

// ! Components
import { Box, CloseButton, useToast } from "@chakra-ui/react";
import BoilerTemplate from "components/BoilerTemplate";
import ReportHeader from "../Header";
import WrapperSpinner from "components/Spinner";
import { Rating } from "components/Rating";
import BackToTop from "components/BackToTop";

// ! Models
import Model from "models";

//  ! Libs
import dayjs from "components/Dayjs"

import {
  aliasReports,
  buildQueryString,
  buildXLSXFromTable,
  compareOrderBy,
  convertEndDate,
  convertStartDate,
  downloadTriggered,
  formatDate,
  groupArrayByField,
  isEmpty,
  mergeArraysByPropertyName,
  renameFile,
  renewInfo,
  uniq,
  xmlInputWithFieldName,
} from "helpers/helpers";
import { dayAdd } from "helpers/chartutils";
import { GetDateShowType } from "helpers/date";
import md5 from "helpers/md5";

import { BREADCRUMBS, DEFAULT_DATA_FETCHING, DEFAULT_FILTER, LIMIT_PRINT_PROJECT } from "pages/ReportStandard/context/store/constants";
import { REPORT_OPEN_COMPARE } from "components/Metrics/constants";
import { OPTIONS_DRAW_BY_REPORT } from "components/Metrics";
import ModalReportDetail from "../Modal";

const Main = () => {
  const { id: reportId, name }: any = useParams<any>();
  const { state, dispatch, actions } = useStore();
  const { updateBreadcrumb, setMenuActive } = useApp();
  const t = useTrans();
  const { push } = useHistory();
  const location = useLocation();
  const roles = useRoles();
  const { showMessage } = useEmbedApp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [duplicateChartType, setDuplicateChartType] = useState("");
  const toast = useToast();
  const toastIdRef = useRef<any>(null);

  const {
    ReportInfo,
    ReportData,
    ReportSummary,
    AutoComplete,
    AutoCompleteById,
    AutoCompleteGateway,
    Export,
    Duplicate,
    Delete,
    ExportProcess,
    StatusModalExport,
    ChangeStatusModalExport,
    ReportRetryData
  } = Model();

  const {
    statistical_table_data,
    info,
    enddate,
    startdate,
    isLoadingTable,
    groupBy,
    groupAreas,
    measure,
    measureSelected,
    groupBySelected,
    dataFetchingTable,
    sortValue,
    filter,
    dimensionSelected,
    filterSelected,
    operatorSelected,
    offset,
    limit,
    isHiddenPagination,
    compareDateStart,
    compareDateEnd,
    optionCompare,
    discount,
    optionMeasure,
    defaultMeasure,
    autoCompleteFilterPanel,
    isLoadingAutoCompleteFilterPanel,
    benchmark,
    heatmapOptions,
    groupByFilterPanel,
    queryStringByAutoComplete,
    typeFilterFromListing,
    isFavoriteFromListing,
    historyPath,
    searchKeyFromListing,
    authors,
    exportId,
    isLoadingPrint,
    dataPrint,
    is_hidden_annouce_export,
    isFavourite,
    reportName,
    isLoadingExportClient,
    dataExport,
    measureDraw,
    modalType,
    duplicateName
  } = state;


  let pageTitle = "";
  let tab = "";

  if (name !== undefined && name !== "" && reportName !== undefined && reportName !== "") {
    tab = t(`tab_name_${BREADCRUMBS[name].label}`);
    pageTitle = `${tab} - ${reportName}`;
  }

  useDetectPage(pageTitle);

  const { data: dataLastestView, isLoading: isLoadingLastestView } = useLastestView(reportId);

  let id = aliasReports(reportId);

  name !== undefined &&
    updateBreadcrumb(t(`tab_name_${BREADCRUMBS[name].label}`), t('all_reports'), '/listing');

  setMenuActive({
    label: "Danh sách báo cáo",
    destination: '/listing'
  });

  const queryString: {
    endDate?: string;
    startDate?: string;
    LocationId?: string;
    SourceName?: string;
    widget_id?: string;
    dashboard_id?: string;
  } = useQueryString();

  // Quyền xem giá vốn
  const canReadMACost =
    roles.includes("admin") ||
    roles.includes("com_api.admin") ||
    roles.includes("com_api.ma_costamount.read");

  // * Sử dụng để build data cho bộ lọc (do sử dụng hook translation nên hàm này không move vào helper)
  const buildFilter = (filterArray) => {
    return filterArray
      .filter(
        (y) =>
          y.lstReportScreenFilterData?.length &&
          y.filterName !== "StartDate" &&
          y.filterName !== "EndDate",
      )
      .map((x) => {
        let query: Array<any> = [];
        x.lstReportScreenFilterData.forEach((q) => {
          const { operatorSymbol, displayValue, filterData } = q;
          query.push({
            conjunction: operatorSymbol === "is" ? "or" : "and",
            displayText: `${t(x.fieldName)} ${t(operatorSymbol)} ${t(
              displayValue,
            )}`,
            query: displayValue,
            symbol: operatorSymbol,
            value: filterData,
          });
        });

        return {
          conjunction: x.currentCondition?.symbol === "is" ? "or" : "and",
          dimension: x.fieldName,
          query: query,
        };
      });
  };

  const clearAllQueryParams = () => {
    if (searchParams.get("startDate")) {
      setSearchParams([]);
    }
  };

  // * ----- Start Khai báo useEffect
  const useEffectDuplicateProject = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [id]);
  };

  const useEffectFetchHiddenConfirmExport = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  // ! Gọi khi clone ID mới
  useEffectDuplicateProject(() => {
    handleDuplicateProject();
  });

  const handleDuplicateProject = async () => {
    if (id && id !== null) {
      let payload = {};

      if (queryString.LocationId) {
        const data = await autoCompleteByQueryParam("LocationId");

        payload = { ...payload, LocationId: data };
      }

      if (queryString.SourceName) {
        const data = await autoCompleteByQueryParam("SourceName");
        payload = { ...payload, SourceName: data };
      }

      dispatch(actions.setQueryStringByAutoComplete(payload));

      setTimeout(() => {
        getInfoReport();
      }, 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffectFetchHiddenConfirmExport(() => {
    fetchStatusHiddenConfirmExport();
  })

  const fetchStatusHiddenConfirmExport = async () => {
    const { isError, data } = await StatusModalExport();

    if (isError) {
      showMessage("Đã có lỗi xảy ra", 'error')
    } else {
      dispatch(actions.setIsDisplayAnnouceExport(data))
    }
  }

  // ! Gọi API get DATA
  const useEffectAfterGetInfo = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [info]);
  };

  // ! Gọi API get DATA khi thay đổi KM
  const useEffectDiscountChange = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [discount]);
  };

  // ! Gọi API khi thay đổi range so sánh
  const useEffectComparisonRangeChange = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [compareDateStart, compareDateEnd]);
  };

  // ! Gọi API get Default autocomplete theo report name
  const useEffectGetDefaultAutoComplete = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [info.reportNameSystem]);
  };

  // ! Lấy tab mặc định từ trang listing
  const useEffectGetTypeFromListingPage = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [location]);
  };

  // ! Export Listener
  const useEffectExportInterval = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [exportId])
  }

  useEffectAfterGetInfo(() => {
    if (!isEmpty(info)) {
      handleFirstLoadDisplayColumn();
    }

    return () => {
      toast.closeAll();
    }
  });

  useEffectDiscountChange(() => {
    if (JSON.stringify(dataFetchingTable) === JSON.stringify(DEFAULT_DATA_FETCHING)) return;

    if (!isEmpty(info)) {
      let newPayload = JSON.parse(JSON.stringify(dataFetchingTable));
      const fetchdata = {
        ...newPayload,
        pagination: {
          offset: 0,
          limit,
        },
      };

      let locIndex = fetchdata.filters.findIndex(
        (m) => m.dimension === "DiscountId",
      );

      if (locIndex === -1) {
        if (discount.id !== undefined) {
          fetchdata.filters.push({
            conjunction: "and",
            dimension: "DiscountId",
            query: [
              {
                conjunction: "or",
                symbol: "is",
                value: `${discount.id}`,
              },
            ],
          });
        }
      } else {
        if (discount.id !== undefined) {
          fetchdata.filters[locIndex] = {
            conjunction: "and",
            dimension: "DiscountId",
            query: [
              {
                conjunction: "or",
                symbol: "is",
                value: `${discount.id}`,
              },
            ],
          };
        } else {
          fetchdata.filters.splice(locIndex, 1);
        }
      }
      dispatch(actions.setFetchDataTable(fetchdata));
      resetPagination();
      fetchDataTable(fetchdata);

      if (compareDateStart !== undefined && compareDateEnd !== undefined) {
        fetchDataPeriodTable(fetchdata);
      }
    }
  });

  useEffectComparisonRangeChange(() => {
    if (
      compareDateStart !== undefined &&
      compareDateEnd !== undefined &&
      dataFetchingTable.groupby.length > 0
    ) {
      fetchDataPeriodTable(dataFetchingTable);
    }
  });

  useEffectGetDefaultAutoComplete(() => {
    if (info.reportNameSystem) {
      autoCompleteByReportName(info.reportNameSystem);
    }
  });

  useEffectGetTypeFromListingPage(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);

      const getParam = (key: string) => searchParams.get(key);

      const typeParams = getParam("type")?.split(",") ?? [];
      const isFavoriteParams = parseInt(getParam("isFavorite") ?? '0', 10);
      const searchKeyParams = getParam("s") ?? '';
      const authors = getParam("authors") ?? '';

      dispatch(actions.setTypeFromListing({
        type: typeParams,
        isFavorite: isFavoriteParams,
        search: searchKeyParams,
        authors: authors
      }))
    }
  })

  useEffectGetTypeFromListingPage(() => {
    let typeParams = '';

    if (location?.search) {
      const searchParams: any = new URLSearchParams(location.search);

      if (searchParams.has("historyPath")) {
        typeParams = searchParams.get('historyPath')
      }

      dispatch(actions.setTypeFromDashboard({
        historyPath: typeParams,
      }))
    }
  })

  useEffectExportInterval(() => {
    if (exportId && exportId?.length) {
      timeoutMethodWith2Times();
    }
  });

  const { data: dataSummary, mutate } = useSummaryDetail(info, {
    daterange: {
      enddate,
      startdate,
    },
    ...dataFetchingTable
  });

  const timeoutMethodWith2Times = async () => {
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    let second = 2000;
    let counter = 0;

    while (true) {
      let flag: boolean = await callAPIExportReport(exportId);

      if (flag) {
        break;
      }

      ++counter;

      if (counter > 5) {
        second = 5000
      }

      await sleep(second);
    }
  }

  const applyDiscountFilter = (payload) => {
    let newPayload = JSON.parse(JSON.stringify(payload));
    const fetchdata = {
      ...newPayload,
    };

    if (info.reportNameSystem === "sales_promotion") {
      if (
        fetchdata.hasOwnProperty("filters") &&
        Array.isArray(fetchdata.filters)
      ) {
        let locIndex = fetchdata.filters.findIndex(
          (m) => m.dimension === "DiscountId",
        );
        if (locIndex === -1) {
          if (discount.id !== undefined) {
            fetchdata.filters.push({
              conjunction: "and",
              dimension: "DiscountId",
              query: [
                {
                  conjunction: "or",
                  symbol: "is",
                  value: `${discount.id}`,
                },
              ],
            });
          }
        } else {
          if (discount.id !== undefined) {
            fetchdata.filters[locIndex] = {
              conjunction: "and",
              dimension: "DiscountId",
              query: [
                {
                  conjunction: "or",
                  symbol: "is",
                  value: `${discount.id}`,
                },
              ],
            };
          } else {
            fetchdata.filters.splice(locIndex, 1);
          }
        }
      }
    }



    return fetchdata;
  };

  const callDoubleApiToComparePeriod = (payload) => {
    if (optionCompare.length && optionCompare !== t("noCompare")) {
      fetchDataTable(payload);
      fetchDataPeriodTable(payload);
    } else {
      fetchDataTable(payload);
    }
  };

  // * ----- End Khai báo useEffect

  // * Start Gọi API Function
  // ! Main: Set ngày bắt đầu và kết thúc
  const implementSetDate = (data) => {
    const startDateFromQr = queryString.startDate;
    const endDateFromQr = queryString.endDate;

    const startDate = data?.lstReportScreenFilter?.find(y => y.filterName === "StartDate")?.lstReportScreenFilterData[0]?.filterDataDateTime || "";
    const endDate = data?.lstReportScreenFilter?.find(y => y.filterName === "EndDate")?.lstReportScreenFilterData[0]?.filterDataDateTime || "";

    if (startDateFromQr && endDateFromQr) {
      dispatch(
        actions.setDate({
          startDate: dayjs(startDateFromQr).startOf('day'),
          endDate: dayjs(endDateFromQr).endOf('day'),
        })
      );
    } else {
      if (startDate && endDate) {
        dispatch(
          actions.setDate({
            startDate: dayjs(startDate),
            endDate: dayjs(endDate),
          })
        );

        const { duration: diffCounts, type: dateShowType } = GetDateShowType(dayjs(startDate), dayjs(endDate));

        if (REPORT_OPEN_COMPARE.includes(info.reportNameSystem)) {
          dispatch(
            actions.setDateCompare({
              startDate: dayjs(dayAdd(dayjs(startDate), diffCounts * -1, dateShowType)),
              endDate: dayjs(dayAdd(dayjs(endDate), diffCounts * -1, dateShowType)),
            })
          );
        }

      } else {
        switch (data.reportNameSystem) {
          case "cas_transaction_pnl":
            dispatch(
              actions.setDate({
                startDate: dayjs().startOf("year"),
                endDate: dayjs(),
              })
            );
            break;

          case "stock_lots":
            dispatch(
              actions.setDate({
                startDate: dayjs(),
                endDate: dayjs().add(dayjs.duration({ 'months': 3 })),
              })
            );
            break;

          case "customers_new_and_return":
          case "customers_by_location":
          case "customers_by_gender":
          case "customers_by_age":
            dispatch(
              actions.setDate({
                startDate: dayjs().subtract(29, "days"),
                endDate: dayjs(),
              })
            );
            break;

          case "customers_by_rfm":
            dispatch(
              actions.setDate({
                startDate: dayjs().subtract(365, "days"),
                endDate: dayjs(),
              })
            );
            break;



          default:
            // Handle default case if needed
            break;
        }
      }
    }
  };

  // ! Lấy info Report
  const implementMetricsDraw = (listMetric) => {
    if (listMetric && listMetric.length) {
      dispatch(actions.setMetricsDraw(listMetric));

      let metricDraw = listMetric.find(metrics => metrics.isSelected);

      if (metricDraw) {
        dispatch(actions.setMeasureYAxis({
          measureField: metricDraw.measureField,
          measureName: metricDraw.measureName
        }));
      }
    }
  };

  const getInfoReport = async () => {
    dispatch(actions.fetchInfo());

    const { data, isError }: any = await ReportInfo(id, queryString.widget_id, queryString.dashboard_id);

    if (isError) {
      if (data.includes("report_not_exist")) {
        showMessage(t("Báo cáo không tồn tại"), "error");
        push(`/${name}`);
      }
      dispatch(actions.fetchInfoError());
    } else {
      const filtersDefault = buildFilter(data?.lstReportScreenFilter);

      implementSetDate(data);
      implementMetricsDraw(data?.lstMeasureDraw || []);

      // Không có quyền xem giá vốn
      if (!canReadMACost) {
        data.lstReportScreenMeasure = data?.lstReportScreenMeasure.filter(
          (y) =>
            ![
              "MACostAmount",
              "CostAmount",
              "Profit",
              "ProfitMargin",
              "CosAmountOpening",
              "CosAmountClosing",
            ].includes(y.measureName),
        );
      }

      // Thêm logic Ẩn/Hiện chỉ số
      data.lstReportScreenMeasure = data?.lstReportScreenMeasure.filter(
        (y) => y.isDisplay,
      );

      let reportScreenMeasures = data?.lstReportScreenMeasure.filter(
        (y) => y.isSelected,
      );

      let reportScreenGroupBy = data?.lstReportScreenGroupProperty
        .filter((y) => y.isSelected)
        .sort((a, b) => {
          return a.selectOrderNumber - b.selectOrderNumber;
        });

      // ! Lưu lại giá trị heatmap đã được lưu trạng thái bật/tắt
      let reportScreenHeatmap = data?.lstReportScreenMeasure
        .filter((y) => y.isSelected)
        .map((y) => y.measureField);

      dispatch(actions.setHeatMapOptions(reportScreenHeatmap));

      let groupAreas: Array<string> = [];
      if (data.reportNameSystem === "sales_promotion") {
        data?.lstReportScreenGroupProperty
          .sort((a, b) => (a.orderNumberGroup > b.orderNumberGroup ? 1 : -1))
          .forEach((item: any) => {
            const groupAreaName: string = item.groupAreaName;
            if (!groupAreas.includes(groupAreaName)) {
              groupAreas.push(groupAreaName);
            }
          });
        data?.lstReportScreenGroupProperty.sort(
          (a, b) => a.selectOrderNumber - b.selectOrderNumber,
        );
      } else {
        data?.lstReportScreenGroupProperty.forEach((item: any) => {
          const groupAreaName: string = item.groupAreaName;
          if (!groupAreas.includes(groupAreaName)) {
            groupAreas.push(groupAreaName);
          }
        });
      }

      data.groupAreas = groupAreas;

      dispatch(actions.setGroupBySelected(reportScreenGroupBy));
      dispatch(actions.setMeasureSelected(reportScreenMeasures));

      // * Set 1 lần cho default
      dispatch(actions.setDefaultGroupBy(reportScreenGroupBy));
      dispatch(actions.setDefaultMeasure(reportScreenMeasures));

      dispatch(
        actions.setFilterSelected(filtersDefault),
      );

      dispatch(actions.fetchInfoSuccess(data));
      dispatch(actions.toggleIsFavourite(data.isFavourite));

      if (data.reportNameSystem === "sales_promotion") {
        const discountFilter = data?.lstReportScreenFilter?.find(
          (y) => y.filterName === "DiscountId",
        );

        if (
          discountFilter &&
          discountFilter.lstReportScreenFilterData.length > 0
        ) {
          const discountFilterData = {
            id: discountFilter.lstReportScreenFilterData[0].filterData,
            displayname_vi:
              discountFilter.lstReportScreenFilterData[0].displayValue,
            specialInfo: {
              is_promotion:
                discountFilter.lstReportScreenFilterData[0].dependdata !==
                  undefined
                  ? discountFilter.lstReportScreenFilterData[0].dependdata
                    .is_promotion
                  : false,
            },
          };

          dispatch(actions.chooseCoupon(discountFilterData));
        }
      }

      // ! TH: là báo cáo sắp hết hàng
      if (data.reportNameSystem === "stock_remaining_days") {
        dispatch(actions.setDimensionSelected("Qty_DayInventoryRemaining"))
      }


      if (data.reportNameSystem === "sales_promotion") {
        dispatch(actions.setMeasureYAxis({
          measureField: "NetSaleAmountAfterDiscount",
          measureName: "NetSaleAmountAfterDiscount",
        }))
      }


      if (data.reportNameSystem === "sales_overview_promotion") {
        dispatch(actions.setMeasureYAxis({
          measureField: "TotalDiscount",
          measureName: "TotalDiscount",
        }))
      }

      let result = [];

      // ! set groupby cho filter panel
      switch (data.reportNameSystem) {
        case "order_shipments_detail_carriers_internal_speed":
          result = data.lstReportScreenGroupProperty.filter(
            (y) => y.groupAreaName === "Time",
          );

          dispatch(
            actions.setGroupByFilterPanel({
              Time: result,
            }),
          );
          break;

        case "order_shipments_overview_carriers_internal_speed":
          result = data.lstReportScreenGroupProperty.filter(
            (y) => y.groupAreaName === "Time",
          );

          dispatch(
            actions.setGroupByFilterPanel({
              Time: result,
            }),
          );
          break;

        default:
          break;
      }
    }
  };

  // ! Lấy data Report (Data + summary)
  const fetchDataTable = async (payload) => {
    dispatch(actions.fetchStatistic());

    let newPayload = JSON.parse(
      JSON.stringify({
        ...applyDiscountFilter(payload),
      }),
    );

    if (newPayload.daterange === undefined) {
      newPayload.daterange = {
        startdate: convertStartDate(startdate),
        enddate: convertEndDate(enddate),
      };
    }


    const { data: dataList, isError: isErrorList }: any = await ReportData(
      id,
      newPayload,
      info.reportNameSystem,
    );

    if (isErrorList) {
      dispatch(actions.fetchStatisticError([]));
    } else {
      dispatch(actions.fetchStatisticSuccess(dataList));

      if (dataList?.data?.length < limit) {
        dispatch(actions.setHiddenPagination(true));
      } else {
        dispatch(actions.setHiddenPagination(false));
      }
    }
  };

  const handlePrintApi = async () => {
    dispatch(actions.getDataPrint());

    const fetchdata = {
      ...dataFetchingTable,
      daterange: {
        enddate,
        startdate,
      },
      pagination: {
        offset: 0,
        limit: LIMIT_PRINT_PROJECT + 1,
      },
    };

    let newPayload = JSON.parse(
      JSON.stringify({
        ...applyDiscountFilter(fetchdata),
      }),
    );

    if (newPayload.daterange === undefined) {
      newPayload.daterange = {
        enddate,
        startdate,
      };
    }

    const { data: dataList, isError: isErrorList }: any = await ReportData(
      id,
      newPayload,
      info.reportNameSystem,
    );

    let dataReturn = {
      ...dataList,
      summary: []
    };

    if (isErrorList) {
      dispatch(actions.getDataPrintError({}));
    } else {

      const { data: dataSummary, isError: isErrorSummary }: any =
        await ReportSummary(id, newPayload);

      if (isErrorSummary) {
        dispatch(actions.fetchSummaryError());
      } else {
        dataReturn = { ...dataReturn, summary: dataSummary }
      }
      dispatch(actions.getDataPrintSuccess(dataReturn));
    }
  }

  // ! Lấy data Report của kỳ nếu có so sánh (Data + summary)
  const fetchDataPeriodTable = async (payload) => {
    dispatch(actions.fetchCompareStatistic());

    let newPayload = JSON.parse(
      JSON.stringify({
        ...applyDiscountFilter(payload),
      }),
    );

    newPayload.daterange = {
      startdate: convertStartDate(compareDateStart),
      enddate: convertEndDate(compareDateEnd),
    };

    if (
      ![
        "stock_transactionhistory_adv",
        "stock_transactionhistory_alltime",
        "stock_lots",
        "cas_transaction_pnl",
      ].includes(info.reportNameSystem)
    ) {
      const { data: dataSummary, isError: isErrorSummary }: any =
        await ReportSummary(
          id,
          {
            ...newPayload,
            daterange: {
              enddate: compareDateEnd,
              startdate: compareDateStart,
            },
          },
        );

      if (isErrorSummary) {
        dispatch(actions.fetchCompareSummaryError());
      } else {
        dispatch(actions.fetchCompareSummarySuccess(dataSummary));
      }
    } else {
      dispatch(actions.fetchCompareSummarySuccess([]));
    }
  };

  // ! Xử lý fetching auto complete
  const getAutoComplete = async (query, isDefault = false) => {
    // ! Cờ isDefault sẽ gọi mặc định cái query trên thanh filter panel
    dispatch(actions.getAutoComplete());

    isDefault && dispatch(actions.getAutoCompleteFilterPanel());

    // Xử lý riêng cho gateway
    if (query.dimension === "Gateway") {
      const { data, isError }: any = await AutoCompleteGateway(id, {
        ...dataFetchingTable,
        daterange: {
          enddate,
          startdate,
        },
      });

      if (isError) {
        dispatch(actions.getAutoCompleteError());
      } else {
        dispatch(actions.getAutoCompleteSuccess(data));
      }
    } else {
      let queryFunction = AutoComplete;

      let queryString = buildQueryString({
        dimension: query.dimension,
        query: query.query || "",
        enddate: dayjs(enddate).endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        startdate: dayjs(startdate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });

      if (query.dimension === "DiscountId") {
        // const findValueRefer = filterSelected.find(y => y.dimension === "DiscountMethod");

        // & TH: là khuyến mãi thì filter thêm hình thức KM (CTKM | MKM)
        queryString = buildQueryString({
          dimension: query.dimension,
          query: query.query || "",
          refer: "",
          enddate: dayjs(enddate).endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          startdate: dayjs(startdate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        });
      }

      if (
        query.dimension === "ProductId" ||
        query.dimension === "CollectionIds"
      ) {
        queryString = buildQueryString({
          dimension: query.dimension,
          isquerybyid: true,
          query: query.query || "",
          enddate: dayjs(enddate).endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          startdate: dayjs(startdate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        });
        queryFunction = AutoCompleteById;
      }
      const { data, isError }: any = await queryFunction(id, queryString);

      if (isError) {
        dispatch(actions.getAutoCompleteError());
        isDefault && dispatch(actions.getAutoCompleteFilterPanelError());
      } else {
        if (!query.query) {
          dispatch(actions.getAutoCompleteDefault(data));
        }
        dispatch(actions.getAutoCompleteSuccess(data));

        isDefault &&
          dispatch(
            actions.getAutoCompleteFilterPanelSuccess({
              [query.dimension]: data,
            }),
          );
      }
    }
  };

  // ! Xử lý fetching pagination
  const getPagination = async (payload) => {
    dispatch(actions.fetchStatistic());

    const { data, isError }: any = await ReportData(
      id,
      {
        daterange: {
          enddate,
          startdate,
        },
        ...applyDiscountFilter(payload),
      },
      info.reportNameSystem,
    );

    if (isError) {
      dispatch(actions.fetchStatisticError([]));
    } else {
      const { data: dataValue, datalink } = statistical_table_data;
      let headerreport = statistical_table_data.headerreport;
      const mergeData = [...dataValue, ...data.data];
      const mergeDataLink = [...datalink, ...data.datalink];

      if (data?.data?.length < limit) {
        dispatch(actions.setHiddenPagination(true));
      } else {
        dispatch(actions.setHiddenPagination(false));
      }

      if (headerreport.length === 0) {
        headerreport = data.headerreport;
      }

      dispatch(
        actions.fetchStatisticSuccess({
          ...data,
          headerreport: headerreport,
          data: mergeData,
          datalink: mergeDataLink,
        }),
      );
    }
  };

  // * End gọi API

  // * ---------- Các Action submit
  // ! Xử lý lần đầu load bảng
  interface QueryItem {
    conjunction: string;
    displayText: string;
    query: string;
    symbol: string;
    value: any;
  }

  interface ResultItem {
    conjunction: string;
    dimension: string;
    query: QueryItem[];
  }

  const buildFilterByQueryString = (nameField: string, list: any[]): ResultItem[] => {
    const query: QueryItem[] = [];
    const result: ResultItem[] = [];

    if (list.length && queryStringByAutoComplete[nameField]?.length) {
      list.forEach((value) => {
        const findQueryInAutoComplete = queryStringByAutoComplete[nameField]?.find(
          (temp) => temp.value === value
        );

        if (findQueryInAutoComplete) {
          const resultQuery: QueryItem = {
            conjunction: "or",
            displayText: `${t(nameField)} ${t("is")} ${findQueryInAutoComplete.displayname_vi}`,
            query: findQueryInAutoComplete.displayname_vi,
            symbol: "is",
            value: value,
          };

          query.push(resultQuery);
        }
      });

      if (query.length) {
        result.push({
          conjunction: "and",
          dimension: nameField,
          query: query,
        });
      }
    }

    return result;
  };

  const handleFirstLoadDisplayColumn = async () => {
    const payload = renewInfo(info, canReadMACost);
    const filters = buildFilter(info?.lstReportScreenFilter);
    let fetchdata: any = { ...payload, filters };

    let addFilterByParams: any = [];

    if (queryString.LocationId) {
      const LocationList = queryString.LocationId.split(",");
      addFilterByParams = [
        ...addFilterByParams,
        ...buildFilterByQueryString("LocationId", LocationList),
      ];
    }

    if (queryString.SourceName) {
      const SourceNameList = queryString.SourceName.split(",");

      addFilterByParams = [
        ...addFilterByParams,
        ...buildFilterByQueryString("SourceName", SourceNameList),
      ];
    }

    fetchdata = {
      ...fetchdata,
      filters: [...fetchdata.filters, ...addFilterByParams],
    };

    dispatch(
      actions.setSortHeader({
        dimension: info.fieldsort,
        direction: info.sortype,
      }),
    );

    dispatch(actions.setFilterSelected([...fetchdata.filters]));

    dispatch(actions.setFetchDataTable(fetchdata));

    resetPagination();
    fetchDataTable(fetchdata);
  };

  // ! Xử lý sort table theo header
  const handleSortTable = (name) => {
    let orderBy = {
      ...sortValue,
    };

    if (name === sortValue.dimension) {
      orderBy = {
        ...orderBy,
        direction: sortValue.direction === "asc" ? "desc" : "asc",
      };
    } else {
      orderBy = {
        dimension: name,
        direction: sortValue.direction === "asc" ? "desc" : "asc",
      };
    }

    const fetchdata = {
      ...dataFetchingTable,
      order_by: orderBy,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setSortHeader(orderBy));
    dispatch(actions.setFetchDataTable(fetchdata));
    resetPagination();
    callDoubleApiToComparePeriod(fetchdata);
  };

  // ! Xác nhận bộ lọc với dimension của bộ lọc
  const handleSubmitFilter = (value) => {
    const newFilter = [...filterSelected];

    const filterIndex = newFilter.findIndex(
      (t) => t.dimension === dimensionSelected,
    );

    const cloneValue = [...value];

    const getValueFilter = cloneValue.map((y) => y.value);

    const createQuery = uniq(getValueFilter)
      .map((que) => {
        if (
          ["inputField", "numberField"].includes(
            xmlInputWithFieldName(dimensionSelected) as string,
          )
        ) {
          return {
            conjunction: "or",
            displayText: `${t(dimensionSelected)} ${t(operatorSelected)} ${t(
              que,
            )}`,
            query: `${que}`,
            symbol: operatorSelected,
            value: que,
          };
        } else {
          const findQuery = value.find(
            (y) => y.dimension === dimensionSelected && y.value === que,
          );

          if (findQuery !== undefined) {
            return {
              conjunction: "or",
              displayText: `${t(dimensionSelected)} ${t(operatorSelected)} ${findQuery.displayname_vi
                }`,
              query: `${findQuery.displayname_vi}`,
              symbol: operatorSelected,
              value: findQuery.value,
            };
          }

          return {
            query: undefined,
          };
        }
      })
      .filter((q) => q.query !== undefined);

    if (createQuery.length > 0) {
      const modelFilter = {
        conjunction: "or",
        dimension: dimensionSelected,
        query: createQuery,
      };

      if (filterIndex !== -1) {
        // ? TH: Filter đã tồn tại
        newFilter.splice(filterIndex, 1);
      }

      newFilter.push(modelFilter);
      dispatch(actions.setFilterSelected(newFilter));
      const payload = {
        ...dataFetchingTable,
        filters: newFilter,
        pagination: {
          offset: 0,
          limit,
        },
      };


      dispatch(actions.setFetchDataTable(payload));
      resetPagination();
      callDoubleApiToComparePeriod(payload);
    } else {
      if (filterIndex !== -1) {
        // ? TH: Filter đã tồn tại
        newFilter.splice(filterIndex, 1);
      }

      const payload = {
        ...dataFetchingTable,
        filters: newFilter,
        pagination: {
          offset: 0,
          limit,
        },
      };

      dispatch(actions.setFilterSelected(newFilter));
      dispatch(actions.setFetchDataTable(payload));
      resetPagination();
      callDoubleApiToComparePeriod(payload);
    }
  };

  const handleSubmitFilterV2 = (filterBox) => {
    // ! Giữ lại filter bị hidden và thay đổi toàn bộ filter còn lại thành filter mới
    let oldFilterBox = [...dataFetchingTable.filters];
    let newFilter = [...filterBox];

    const result = mergeArraysByPropertyName(oldFilterBox, newFilter, "dimension", info.reportNameSystem);

    const payload = {
      ...dataFetchingTable,
      filters: result,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFilterSelected(result));
    dispatch(actions.setFetchDataTable(payload));
    resetPagination();
    callDoubleApiToComparePeriod(payload);
  }

  // ! Xác nhận bộ lọc với nhiều dimension 
  const handleSubmitFilterWithDimension = (value) => {
    const newFilter = [...filterSelected];
    const getDimensionWhenInputValue = uniq(value.map((y) => {
      return y.dimension
    }));

    // ! B1 : Xem input filter list có dimension nào trùng với dimension hiện tại hay không ? có thì sẽ remove ra tất cả chỉ lấy dimension không trùng
    let val = newFilter.filter((el) => !getDimensionWhenInputValue.includes(el.dimension));

    // ! B2: Group các dimension từ input lại với nhau thành object
    const groupDimensionWithInput = groupArrayByField(value, 'dimension');

    // ! B3: Build Query từ Input
    let final: any = [];
    for (const props in groupDimensionWithInput) {
      let resultQuery: any = [];

      groupDimensionWithInput[props].forEach((query) => {

        let result = {
          conjunction: "and",
          displayText: `${t(query.dimension)} ${t(query.symbol)} ${query.value}`,
          query: query.value,
          symbol: query.symbol,
          value: query.value
        }

        resultQuery.push(result)
      })

      const resultFilter = {
        conjunction: "or",
        dimension: props,
        query: resultQuery
      };

      final.push(resultFilter)
    }

    const assignNewFilter = [...val, ...final]

    const payload = {
      ...dataFetchingTable,
      filters: assignNewFilter,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFilterSelected(assignNewFilter));
    dispatch(actions.setFetchDataTable(payload));
    resetPagination();
    callDoubleApiToComparePeriod(payload);
  };

  // ! Cancel filter
  const handleCloseFilter = (filter) => {
    const newFilter = [...filterSelected];

    newFilter.forEach((_, i) => {
      if (newFilter[i].dimension === filter.dimension) {
        const index = newFilter[i].query.findIndex(
          (y) => y.value === filter.value,
        );

        if (newFilter[i].query.length === 1) {
          if (index !== -1) {
            newFilter.splice(i, 1);
          }
        } else {
          if (index !== -1) {
            newFilter[i].query.splice(index, 1);
          }
        }
      }
    });
    dispatch(actions.setFilterSelected(newFilter));
    const payload = {
      ...dataFetchingTable,
      filters: newFilter,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFetchDataTable(payload));
    resetPagination();
    callDoubleApiToComparePeriod(payload);
  };

  // ! Nhân bản báo cáo
  const duplicateReport = async () => {
    dispatch(actions.duplicateAction());
    dispatch(actions.toggleVisibleDrilldown(false))

    const { data, isError } = await Duplicate(id, {
      ...dataFetchingTable,
      daterange: {
        enddate,
        startdate,
      },
      reportName: duplicateName,
      ...(measureDraw.length && { measure_draw: [optionMeasure.measureField] }),
      ...(OPTIONS_DRAW_BY_REPORT.hasOwnProperty(info.reportNameSystem) && duplicateChartType.length && {
        displayChartMode: duplicateChartType
      })
    });

    if (isError) {
      showMessage(t(data[0]), "error");
      dispatch(actions.duplicateActionError());
    } else {
      info.reportNameSystem === "sales_promotion"
        ? push(`/sales/promotion/${data}`)
        : push(`/${name}/${data}`);
      dispatch(actions.duplicateActionSuccess());
      dispatch(actions.setModalType(""))
    }
  };

  // ! Xoá báo cáo
  const deleteReport = async () => {
    dispatch(actions.deleteAction());

    const { isError } = await Delete(id);

    if (isError) {
      dispatch(actions.deleteActionError());
    } else {
      push(`/listing`);
      dispatch(actions.deleteActionSuccess());
      showMessage(t("Xóa báo cáo thành công!"));
    }
  };

  // ! Xuất báo cáo được access
  const exportReportServer = async () => {
    dispatch(actions.exportAction());

    const { isError, data } = await Export(id, {
      ...applyDiscountFilter(dataFetchingTable),
      daterange: {
        enddate: dayjs(enddate).endOf('day'),
        startdate: dayjs(startdate).startOf('day'),
      },
    });

    if (is_hidden_annouce_export) {
      dispatch(actions.setIsDisplayAnnouceExport(true));
      await ChangeStatusModalExport(is_hidden_annouce_export);
    }

    // * Xuất báo cáo về máy
    if (isError) {
      closeToast();

      dispatch(actions.exportActionError());

      addToast({
        status: "error",
        isClosable: false,
        description: t("Error_An_error_occurred"),
        duration: 3000
      })
    } else {
      closeToast();

      dispatch(actions.exportActionSuccess());
      dispatch(actions.setExportId(data));

      addToast({
        status: "default",
        isClosable: false,
        description: t("Report_is_being_exported"),
        duration: null
      })
    }
  };

  // ! Gọi xuất báo cáo
  const callAPIExportReport = async (exportId: string) => {
    dispatch(actions.exportAction());

    const { isError, data } = await ExportProcess(exportId);

    if (isError) {
      closeToast();

      dispatch(actions.exportActionError());

      addToast({
        status: "error",
        isClosable: false,
        description: t("Đã có lỗi xảy ra"),
        duration: 2000
      });

      dispatch(actions.setExportId(""));

      return true;
    } else {
      dispatch(actions.exportActionSuccess());

      switch (data.status) {
        case 3:
          // * TH xảy ra lỗi
          closeToast();

          addToast({
            status: "error",
            isClosable: false,
            description: t("Đã có lỗi xảy ra"),
            duration: 2000
          });

          dispatch(actions.setExportId(""));

          return true;

        case 1:
        case 4:
          // * 1. đang lấy dữ liệu
          // * 4. đang tạo file
          return false;

        case 2:
          // * TH: đã có file
          closeToast();

          addToast({
            status: "success",
            isClosable: true,
            description: t("Report_exported"),
            duration: 3000
          });

          dispatch(actions.setExportId(""));

          setTimeout(() => {
            let i = 0;
            let range = data?.files?.length;

            let intervalId = setInterval(() => {
              downloadTriggered(data.files[i], `${renameFile(t(info.reportName))}_${formatDate(dayjs(Date.now()), "DDMMYY")}-${exportId.toString().slice(-3)}${range === 1 ? "" : i + 1}.xlsx`);

              i++;

              if (range === i) {
                clearInterval(intervalId)
              }
            }, 400);
          }, 1000);

          return true;
      }

      return true;
    }

  };

  // ! OnChange 1 field filter nào đó ảnh hưởng tới filter tổng và filter đó move ra riêng thành 1 filter riêng (loại multiple checkbox)
  const handleChangeFilter = (dimension, value) => {
    const getListQuery = autoCompleteFilterPanel[dimension];

    let payload = [];

    if (getListQuery && getListQuery.length) {
      payload = value.map((query) => {
        let findItem: any = {};

        if (getListQuery.length) {
          findItem = getListQuery.find((y) => y.value === query)
        }

        return {
          dimension,
          displayname_vi: findItem.displayname_vi,
          searchKey: findItem.displayname_vi,
          value: findItem.value,
        };
      });
    } else {
      payload = value.map((item) => {
        return {
          dimension,
          displayname_vi: item.query,
          searchKey: item.query,
          value: item.value,
        };
      })
    }

    handleSubmitFilter(payload);
  };

  // ! On change 1 field có loại là single checkbox
  const handleChangeCheckboxInFilterTypeSingle = ({
    type,
    groupAreaName,
    value,
  }: any) => {
    if (type === "groupby") {
      // ! Loại bỏ hết tất cả các Groupby đã chọn có giá trị groupAreaName với onChange
      const result = [...groupBySelected];
      let newValue = result.filter((y) => y.groupAreaName !== groupAreaName);

      newValue.push(value);

      // ? Lọc theo groupPropertyName

      let newValueForDropDown = newValue.map((y) => y.groupPropertyName);

      const newResult = [...groupBy];
      const currentList = new Array<any>();

      newResult.forEach((defaultItem) => {
        newValueForDropDown.forEach((nameFieldActive) => {
          if (defaultItem.groupPropertyName === nameFieldActive) {
            defaultItem.isSelected = true;
            currentList.push(defaultItem);
          }
        });
      });

      dispatch(actions.setGroupBySelected(currentList));
      dispatch(actions.setGroupBy(newResult));
      let filterGroupBy: any = [];

      if (
        info.reportNameSystem ===
        "order_shipments_detail_carriers_internal_speed" ||
        info.reportNameSystem ===
        "order_shipments_overview_carriers_internal_speed"
      ) {
        // ? Với report order_shipments_detail_carriers_internal_speed auto hardcode thời gian sẽ là thứ filter đầu tiên
        filterGroupBy = currentList
          .sort((a) => {
            if (a.groupAreaName === "Time") {
              return -1;
            } else {
              return 1;
            }
          })
          .filter((y) => y.isSelected === true)
          .map((x) => x.groupPropertyName.toLowerCase());
      }

      const selectedGroupBy = currentList
        .sort((a) => {
          if (a.groupAreaName === "Time") {
            return -1;
          } else {
            return 1;
          }
        })
        .filter((y) => y.isSelected === true);

      const order_by = compareOrderBy(
        {
          groupBy: selectedGroupBy,
          measure: measureSelected,
          sort: sortValue,
          lastestOrderBy: dataFetchingTable.order_by
        }
      );

      dispatch(actions.setSortHeader(order_by))

      const fetchdata = {
        ...dataFetchingTable,
        groupby: filterGroupBy,
        pagination: {
          offset: 0,
          limit,
        },
        order_by,
      };

      dispatch(actions.setFetchDataTable(fetchdata));
      resetPagination();
      callDoubleApiToComparePeriod(fetchdata);
    } else if (type === "measure") {
      const getListQuery = autoCompleteFilterPanel[groupAreaName];
      const findItem = getListQuery.findIndex((y) => y.value === value.value);

      let payload: any = [];

      if (findItem !== -1) {
        payload = [
          {
            dimension: groupAreaName,
            displayname_vi: getListQuery[findItem]?.displayname_vi || "",
            searchKey: getListQuery[findItem]?.displayname_vi || "",
            value: getListQuery[findItem]?.value || "",
          },
        ];
      }
      handleSubmitFilter(payload);
    }
  };
  // * -------- End action submit

  // * -------- Start các Action Onchange

  // ! Làm mới báo cáo
  const doRefresh = () => {
    const fetchdata = {
      ...dataFetchingTable,
      daterange: {
        enddate,
        startdate,
      },
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.changeStatusRefresh(true));

    dispatch(actions.setFetchDataTable(fetchdata));

    resetPagination();
    callDoubleApiToComparePeriod(fetchdata);
    mutate(Date.now()); // Gọi dòng summary
    fetchSummaryData()
  };

  // ! Set ngày
  const onChangeDate = (startDate, endDate) => {
    dispatch(actions.setDate({ startDate, endDate }));
    clearAllQueryParams();

    const fetchdata = {
      ...dataFetchingTable,
      daterange: {
        enddate: endDate,
        startdate: startDate,
      },
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFetchDataTable(fetchdata));
    resetPagination();
    fetchDataTable(fetchdata);
  };

  // ! Set ngày so sánh
  const onChangeCompareDate = (startDate, endDate, label) => {
    if (label && label.length && label !== t("noCompare")) {
      resetPagination();
      dispatch(actions.setDateCompare({
        startDate: startDate.startOf('day'),
        endDate: endDate.endOf("day"),
      }));
    } else {

      dispatch(actions.setDateCompare({
        startDate,
        endDate,
      }));
    }
  };

  // ! Set OnChange GroupBy dạng Dropdown
  const handleChangeGroupBy = (value) => {
    const newResult = [...groupBy];
    const currentList = new Array<any>();

    for (var i = 0; i < newResult.length; i++) {
      newResult[i].isSelected = newResult[i].dataFormat === value;
      if (newResult[i].isSelected) {
        currentList.push(newResult[i]);
      }
    }
    dispatch(actions.setGroupBySelected(currentList));
    dispatch(actions.setGroupBy(newResult));

    let filterGroupBy = currentList
      .filter((y) => y.isSelected === true)
      .map((x) => x.groupPropertyName.toLowerCase());

    const fetchdata = {
      ...dataFetchingTable,
      groupby: filterGroupBy,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFetchDataTable(fetchdata));
    resetPagination();
    callDoubleApiToComparePeriod(fetchdata);
  };

  // ! Build data Show Column
  const buildColumn = (selectedGroupByItems, selectedMesureItems) => {
    const newResult = [...groupBy];
    const newResultMesure = [...measure];

    for (var i = 0; i < newResult.length; i++) {
      const item = newResult[i];
      let found = selectedGroupByItems.findIndex((si) => si.id === item.id);
      if (found !== -1) {
        item.isSelected = true;
        newResult[i] = item;
      }
    }

    for (var m = 0; m < newResultMesure.length; m++) {
      const item = newResultMesure[m];
      let found = selectedMesureItems.findIndex((si) => si.id === item.id);
      if (found !== -1) {
        item.isSelected = true;
        newResultMesure[m] = item;
      }
    }

    dispatch(actions.setGroupBySelected(selectedGroupByItems));
    dispatch(actions.setGroupBy(newResult));

    dispatch(actions.setMeasureSelected(selectedMesureItems));
    dispatch(actions.setMeasure(newResultMesure));

    let filterGroupBy = [];

    if (
      info.reportNameSystem ===
      "order_shipments_detail_carriers_internal_speed" ||
      info.reportNameSystem ===
      "order_shipments_overview_carriers_internal_speed"
    ) {
      filterGroupBy = selectedGroupByItems
        .sort((a) => {
          if (a.groupAreaName === "Time") {
            return -1;
          } else {
            return 1;
          }
        })
        .filter((y) => y.isSelected === true)
        .map((x) => x.groupPropertyName.toLowerCase());
    } else {
      filterGroupBy = selectedGroupByItems
        .filter((y) => y.isSelected === true)
        .map((x) => x.groupPropertyName.toLowerCase());
    }

    let filterMeasure = selectedMesureItems
      .filter((y) => y.isSelected)
      .map((x) => {
        return {
          measureField: x.measureField,
          measureName: x.measureName,
        };
      });


    return { filterGroupBy, filterMeasure }
  }

  // ! Set Onchange checkbox
  const handleChangeColumnShowModal = (
    selectedGroupByItems,
    selectedMesureItems,
  ) => {
    let { filterGroupBy, filterMeasure } = buildColumn(selectedGroupByItems, selectedMesureItems)

    //  ! Kiểm tra xem cột đó đã bị move ra khi sort
    const order_by = compareOrderBy(
      {
        groupBy: selectedGroupByItems,
        measure: selectedMesureItems,
        sort: sortValue,
        lastestOrderBy: dataFetchingTable.order_by
      }
    );

    dispatch(actions.setSortHeader(order_by));

    const fetchdata = {
      ...dataFetchingTable,
      groupby: filterGroupBy,
      metrics: filterMeasure,
      pagination: {
        offset: 0,
        limit,
      },
      order_by,
    };

    dispatch(actions.setFetchDataTable(fetchdata));
    resetPagination();
    callDoubleApiToComparePeriod(fetchdata);
  };

  const handleChangeCheckboxChart = (checkbox) => {
    const { name, check, type } = checkbox;

    if (type === "group_by") {
      const newResult = [...groupBy];

      const updateIndex = newResult.findIndex(
        (obj) => obj.groupPropertyName === name,
      );

      newResult[updateIndex].isSelected = check;

      if (check) {
        const currentList = [...groupBySelected] as any;
        currentList.push(newResult[updateIndex]);

        dispatch(actions.setGroupBySelected(currentList));
      } else {
        const currentList = [...groupBySelected] as any;
        const index = currentList.findIndex(
          (obj) => obj.groupPropertyName === name,
        );
        currentList.splice(index, 1);

        dispatch(actions.setGroupBySelected(currentList));
      }
    } else {
      let payloadMeasure: any = [];
      const newResult = [...measure];
      const updateIndex = newResult.findIndex(
        (obj) => obj.measureName === name || obj.measureField === name,
      );

      newResult[updateIndex].isSelected = check;

      if (check) {
        const currentList = [...measureSelected] as any;

        // ! Đẩy các selected vào lại đúng vị trí cũ của nó
        const findOldIndexOfMeasure = defaultMeasure.findIndex(
          (y) => y.measureName === name,
        );

        currentList.splice(findOldIndexOfMeasure, 0, newResult[updateIndex]);

        dispatch(actions.setMeasureSelected(currentList));
        payloadMeasure = currentList;
      } else {
        const currentList = [...measureSelected] as any;
        const index = currentList.findIndex((obj) => obj.measureName === name);
        currentList.splice(index, 1);

        dispatch(actions.setMeasureSelected(currentList));

        payloadMeasure = currentList;
      }

      let filterMeasure = payloadMeasure
        .filter((y) => y.isSelected)
        .map((x) => {
          return {
            measureField: x.measureField,
            measureName: x.measureName,
          };
        });

      const payload = {
        ...dataFetchingTable,
        metrics: filterMeasure,
        pagination: {
          offset: 0,
          limit,
        },
      };

      dispatch(actions.setFetchDataTable(payload));
      resetPagination();
      callDoubleApiToComparePeriod(payload);
    }
  };

  // ! Change Pagination
  const handleChangePage = () => {
    const payload = {
      ...dataFetchingTable,
      pagination: {
        offset: offset + 1,
        limit,
      },
    };
    dispatch(actions.setOffset(offset + 1));
    getPagination(payload);
  };

  const resetPagination = () => {
    dispatch(actions.setOffset(0));
  };

  // & #Filter Step 1
  const autoCompleteByReportName = (reportName) => {
    if (DEFAULT_FILTER.includes(reportName)) {
      switch (reportName) {
        case "shipments_carriers_status_by_time":
          // ! Tình trạng giao hàng theo thời gian
          getAutoComplete(
            {
              dimension: "CarrierId",
            },
            true,
          );

          break;

        case "shipments_carriers_status_by_location":
          // ! Tình trạng giao hàng theo khu vực
          getAutoComplete(
            {
              dimension: "CarrierId",
            },
            true,
          );

          break;

        case "order_shipments_detail_carriers_internal_speed":
          // ! Chi tiết tốc độ đơn nội bộ (lấy kho xuất)
          getAutoComplete(
            {
              dimension: "FromLocId",
            },
            true,
          );

          break;

        case "order_shipments_overview_carriers_internal_speed":
          // ! Chi tiết tốc độ đơn nội bộ (lấy kho xuất)
          getAutoComplete(
            {
              dimension: "FromLocId",
            },
            true,
          );

          break;

        case "stock_remaining_days":
          // ! Sắp hết hàng
          getAutoComplete(
            {
              dimension: "LocId",
            },
            true,
          );

          break;

        case "sales_orders_salechannel_branch":
          // ! Kênh sàn thương mại điện tử
          getAutoComplete(
            {
              dimension: "SalesChannelBranchName",
            },
            true,
          );
          break;

        case "stock_invreceive_by_suppliers":
          // ! Nhập trả hàng theo nhà cung cấp
          getAutoComplete(
            {
              dimension: "SupplierId",
            },
            true,
          );

          break;

        default:
          break;
      }
    }
  };

  const autoCompleteByQueryParam = async (query) => {
    let queryFunction = AutoComplete;

    let queryString = buildQueryString({
      dimension: query,
      query: query.query || "",
    });
    const { data, isError }: any = await queryFunction(id, queryString);

    if (isError) {
      //   dispatch(actions.getAutoCompleteError());
      //  dispatch(actions.getAutoCompleteFilterPanelError());
    } else {
      return data;
    }
  };

  // ! Add toast
  const addToast = ({ status, isClosable, description, duration }) => {
    toastIdRef.current = toast({
      variant: status,
      icon: <>
        {status === "success" && <span className="hrv-report-d-flex hrv-report-items-center">
          {svgCheckedIcon}
        </span>}

        {status === "default" && <Box display="flex" alignItems={'center'}>
          <WrapperSpinner size="sm" variant="white" />
        </Box>}

        {status === "error" && <></>}
      </>,
      description: <Box display="flex" alignItems={'center'} justifyContent={"space-between"}>
        <p className="hrv-report-mb-0">
          {description}
        </p>

        {isClosable && <CloseButton size="sm" ml={2} onClick={closeToast} />}
      </Box>,
      duration: duration,
    })
  }

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }
  // * ---------- End các Action Onchange

  // * -- Actions For Chart In Promotion Report
  const condition = (): boolean | undefined => {
    let flat;

    // & Chi tiết khuyến mãi
    if (info.reportNameSystem === "sales_promotion") {
      // * True => Gọi cả 2 (current và previous) | False Gọi 1 (current)
      // ! note: với trường hợp là undefined thì không gọi API khi và chỉ khi có discount thì mới thật sự gọi API

      // ! TH1: không còn discount không vẽ
      if (!isEmpty(discount)) {
        // ! TH2: có discount thì vẽ 1 line
        flat = false;

        if (optionCompare.length && optionCompare !== t("noCompare")) {
          // ! TH3: có discount và có option kỳ thì vẽ 2 line
          flat = true;
        }
      }
    }

    // & Tổng quan khuyến mãi
    if (info.reportNameSystem === "sales_overview_promotion") {
      // * True => Gọi cả 2 (current và previous) | False Gọi 1 (current)
      // ! note: với trường hợp là undefined thì không gọi API khi và chỉ khi có discount thì mới thật sự gọi API

      if (optionCompare.length && optionCompare !== t("noCompare")) {
        // ! TH3: có discount và có option kỳ thì vẽ 2 line
        flat = true;
      } else {
        flat = false;
      }
    }

    return flat;
  };

  let newPayloadFetchTable = JSON.parse(
    JSON.stringify({
      ...applyDiscountFilter(dataFetchingTable),
    }),
  );

  let groupby_custom: any = undefined;
  let pagination_custom: any = undefined;
  let meatric_custom: any = undefined;

  if (info.reportNameSystem === "sales_overview_promotion") {
    // ! Nếu là hiệu quả khuyến mãi luôn luôn lấy cột đầu tiên groupby để vẽ X
    groupby_custom = [groupBySelected[0].groupPropertyName.toLowerCase()];

    meatric_custom = {
      measureField: "DiscountAmount",
      measureName: "DiscountAmount",
    };

    if (groupBySelected[0].groupPropertyName === "OrderDate") {
      pagination_custom = {
        offset: 0,
        limit: 100,
      }
    } else {
      pagination_custom = {
        offset: 0,
        limit: 10,
      }
    }
  }

  const { current, previous, isLoading, fetchSummaryData } = useFetchDataChart(
    id,
    info.reportNameSystem,
    {
      ...newPayloadFetchTable,
      compareDateStart,
      compareDateEnd,
      startdate,
      enddate,
      metrics: [meatric_custom],
      optionMeasure: info.reportNameSystem === "sales_overview_promotion" ? false : optionMeasure,
      order_by: sortValue
    },
    [
      discount,
      startdate,
      enddate,
      compareDateStart,
      compareDateEnd,
      info.reportNameSystem === "sales_overview_promotion" ? false : optionMeasure,
      filterSelected,
      info.reportNameSystem === "sales_overview_promotion" ? groupBySelected[0]?.groupPropertyName : false,
      sortValue
    ],
    condition(),
    groupby_custom,
    pagination_custom
  );

  const handleChangeYAxis = (payload) => {
    dispatch(actions.setMeasureYAxis(payload));
  };

  const handleChangeHeatmap = (payload) => {
    dispatch(actions.setHeatMapOptions(payload));
  };

  const handleOnClickAbout = (reportSystemName) => {
    dispatch(actions.setModalType("about"));

    switch (reportSystemName) {
      case "stock_remaining_days":
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Sản phẩm sắp hết hàng"),
            body: t("body_about_stock_remaining_days"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000105313-b%C3%A1o-c%C3%A1o-s%E1%BA%AFp-h%E1%BA%BFt-h%C3%A0ng'
          }),
        );

        break;

      case "sales_orders_salechannel_branch":
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Kênh sàn thương mại điện tử"),
            body: t("body_sales_orders_salechannel_branch"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000104777-b%C3%A1o-c%C3%A1o-k%C3%AAnh-b%C3%A1n-h%C3%A0ng-s%C3%A0n-th%C6%B0%C6%A1ng-m%E1%BA%A1i-%C4%91i%E1%BB%87n-t%E1%BB%AD'
          }),
        );

        break;

      case "cas_transaction_pnl":
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Kết quả hoạt động kinh doanh"),
            body: t("body_cas_transaction_pnl"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000093987-b%C3%A1o-c%C3%A1o-k%E1%BA%BFt-qu%E1%BA%A3-kinh-doanh'
          }),
        );

        break;

      case "stock_transactionhistory_adv":
        dispatch(
          actions.setAboutContent({
            header: t("Bảng kê xuất nhập tồn chi tiết theo sản phẩm"),
            body: t("body_stock_transactionhistory_adv"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000098053-ba%CC%89ng-k%C3%AA-xu%C3%A2%CC%81t-nh%C3%A2%CC%A3p-t%C3%B4%CC%80n-chi-ti%C3%AA%CC%81t-theo-sa%CC%89n-ph%C3%A2%CC%89m'
          }),
        );

        break;

      case "stock_transactionhistory_alltime":
        dispatch(
          actions.setAboutContent({
            header: t("Bảng kê xuất nhập tồn theo thời gian"),
            body: t("body_stock_transactionhistory_alltime"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000102390-b%E1%BA%A3ng-k%C3%AA-xu%E1%BA%A5t-nh%E1%BA%ADp-t%E1%BB%93n-theo-th%E1%BB%9Di-gian'
          }),
        );
        break;


      case "sales_order_assigned_location": {
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Doanh thu theo kho xuất"),
            body: t("body_sales_order_assigned_location"),
            footer: ""
          }),
        );

        return;
      }

      case "sales_payments_paiddate": {
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Dòng tiền theo ngày giao dịch"),
            body: t("body_sales_payments_paiddate"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000084482-b%C3%A1o-c%C3%A1o-t%C3%A0i-ch%C3%ADnh'
          }),
        );

        return;
      }

      case "sales_transaction_by_vat": {
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Tổng doanh thu hàng hoá và thuế VAT"),
            body: t("body_sales_transaction_by_vat"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000108189-b%C3%A1o-c%C3%A1o-t%E1%BB%95ng-doanh-thu-v%C3%A0-thu%E1%BA%BF-vat'
          }),
        );

        return;
      }

      case "customers_new_and_return": {
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Khách mới, khách cũ"),
            body: t("body_customers_new_and_return"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000107973-b%C3%A1o-c%C3%A1o-kh%C3%A1ch-h%C3%A0ng-m%E1%BB%9Bi-kh%C3%A1ch-h%C3%A0ng-c%C5%A9'
          }),
        );

        return;
      }

      case "customers_by_rfm": {
        dispatch(
          actions.setAboutContent({
            header: t("Báo cáo Khách hàng theo phân khúc RFM"),
            body: t("body_customers_by_rfm"),
            footer: 'https://support.haravan.com/support/solutions/articles/42000109124-b%C3%A1o-c%C3%A1o-kh%C3%A1ch-h%C3%A0ng-theo-ph%C3%A2n-kh%C3%BAc-rfm'
          }),
        );

        return;
      }

      default:
        break;
    }
  };

  const handleClickExportLocal = async () => {
    if (["cas_transaction_pnl", "stock_lots"].includes(info.reportNameSystem)) {
      // ! TH là báo cáo kết quả hoạt động KD (Do thời gian chọn chỉ theo năm nên loại bỏ rules kiểm tra date range)
      if (!is_hidden_annouce_export) {
        dispatch(actions.setModalType('export'));
      } else {
        callExportApi();
      }
    } else {
      const isValidToExport = enddate.diff(startdate, "days") <= 366;

      if (isValidToExport) {
        if (!is_hidden_annouce_export) {
          dispatch(actions.setModalType('export'));

        } else {
          callExportApi();
        }
      } else {
        dispatch(actions.setModalType("invalidDate"))
      }
    }
  };

  const callExportApi = async () => {
    dispatch(actions.getDataExport());

    addToast({
      status: "default",
      isClosable: false,
      description: t("Report_is_being_exported"),
      duration: null
    });

    // & Step1: Call APIs Query with length 2001
    const fetchdata = {
      ...dataFetchingTable,
      daterange: {
        enddate,
        startdate,
      },
      pagination: {
        offset: 0,
        limit: LIMIT_PRINT_PROJECT + 1,
      },
    };

    let newPayload = JSON.parse(
      JSON.stringify({
        ...applyDiscountFilter(fetchdata),
      }),
    );

    if (newPayload.daterange === undefined) {
      newPayload.daterange = {
        enddate,
        startdate,
      };
    }

    const { data: dataList, isError: isErrorList }: any = await ReportRetryData(
      id,
      newPayload,
      info.reportNameSystem,
    );

    let dataReturn = {
      ...dataList,
    };

    // & Step2: API load done
    if (isErrorList) {
      // xuất -> gọi query -> lỗi 500 -> chờ 2s -> retry -> lỗi 500 -> chờ 2s -> retry -> lỗi 500 -> gọi luồng cũ :)
      exportReportServer();
      dispatch(actions.getDataExportError([]));
    } else {
      // & Step3: if length > 2000 => old flow Export
      if (dataReturn?.data?.length > 2000) {
        exportReportServer();

        dispatch(actions.getDataExportError([]));
      } else {

        if (is_hidden_annouce_export) {
          dispatch(actions.setIsDisplayAnnouceExport(true));

          await ChangeStatusModalExport(!is_hidden_annouce_export);
        }

        dispatch(actions.getDataExportSuccess(dataReturn));
      }
    }
  };

  useEffect(() => {
    if (!isLoadingExportClient && dataExport?.data?.length) {
      // & Step4: Export File
      const getTable = document.getElementById("table-print");
      const key = md5(JSON.stringify(dataFetchingTable));

      if (!getTable) {
        toast.closeAll();

        return showMessage(t("Error_An_error_occurred"), 'error')
        // return addToast({
        //   status: "error",
        //   isClosable: true,
        //   description: t("Error_An_error_occurred"),
        //   duration: 3000
        // });
      }

      buildXLSXFromTable(getTable, `${renameFile(t(info.reportName))}_${formatDate(dayjs(Date.now()), "DDMMYY")}-${key.toString().toLocaleLowerCase().slice(-3)}`, dataExport.headerreport, t);

      toast.closeAll();

      showMessage(t("Report_exported"), 'success')

      // addToast({
      //   status: "success",
      //   isClosable: true,
      //   description: t("Report_exported"),
      //   duration: 3000
      // });


      dispatch(actions.getDataExportError([]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingExportClient, dataExport]);

  // & Function trigger when changed two value: (GroupBy and Measures) and Filter call 1 APIs with 2 params
  const handleTriggerGroupByAndFilter = ({
    filters,
    groupBy,
    measures,
  }) => {
    const { filterGroupBy, filterMeasure } = buildColumn(groupBy, measures)

    const payload = {
      ...dataFetchingTable,
      filters,
      groupby: filterGroupBy,
      metrics: filterMeasure,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFilterSelected(filters));
    dispatch(actions.setFetchDataTable(payload));

    resetPagination();
    callDoubleApiToComparePeriod(payload);
  }

  // & Close Filter by dimension
  const handleCloseFilterByDimension = (dimension) => {
    const filterTagDefault = [...filterSelected];
    const findIndexRemove = filterTagDefault.findIndex(query => query.dimension === dimension);
    const index = dataFetchingTable.filters.findIndex(query => query.dimension === dimension);
    filterTagDefault.splice(findIndexRemove, 1);
    dataFetchingTable.filters.splice(index, 1);

    const payload = {
      ...dataFetchingTable,
      pagination: {
        offset: 0,
        limit,
      },
    };

    dispatch(actions.setFilterSelected(filterTagDefault));
    dispatch(actions.setFetchDataTable(payload));
    resetPagination();
    callDoubleApiToComparePeriod(payload);
  }

  const handleChangeChartType = (item) => {
    if (item.hasOwnProperty("groupbytable") && item?.groupbytable?.length) {
      const grouplist = item.groupbytable.map((group) => {
        const item = groupBy.find(i => i.groupPropertyName === group);
        return item;
      });

      dispatch(actions.setGroupBySelected(grouplist));
      let order_by = {};

      if (item.groupbytable.includes('Day')) {
        dispatch(
          actions.setSortHeader({
            dimension: 'Day',
            direction: 'asc',
          }),
        );

        order_by = {
          dimension: 'Day',
          direction: 'asc',
        }


      } else {
        dispatch(
          actions.setSortHeader({
            dimension: item.groupbytable[1],
            direction: 'asc',
          }),
        );

        order_by = {
          dimension: item.groupbytable[1],
          direction: 'asc',
        }
      }

      setDuplicateChartType(item.chartType)

      const payload = {
        ...dataFetchingTable,
        groupby: item.groupbytable.map(y => y.toLocaleLowerCase()),
        ...(!isEmpty(order_by) && { order_by }),
      };

      dispatch(actions.setFetchDataTable(payload));

      resetPagination();
      callDoubleApiToComparePeriod(payload);
    };
  };

  const handleOpenModal = (type) => {
    switch (type) {
      case "export":
        handleClickExportLocal();
        break;

      case "duplicate":
        const generateName = `${t(reportName)} ${formatDate(
          startdate,
          "DD/MM/YYYY",
        )}-${formatDate(enddate, "DD/MM/YYYY")}`;

        dispatch(actions.setDuplicateName(generateName));
        dispatch(actions.setModalType('duplicate'));
        break;


      case "delete":
        dispatch(actions.setModalType('delete'))
        break;

      default:
        break;
    };

  }

  const handleApplyInModal = (type) => {
    switch (type) {
      case 'export':
        callExportApi();
        dispatch(actions.setModalType(""))
        break;

      case 'duplicate':
        duplicateReport();
        break;

      case 'delete':
        deleteReport();
        break;

      default:
        break;
    }
  }

  return (
    <>
      {reportName === undefined || reportName === "" ? (
        <div style={{
          height: "100vh",
        }} className="hrv-report-d-flex hrv-report-items-center">
          <WrapperSpinner />
        </div>
      ) : (
        <div className="">
          <div className="hrv-report-row">
            <div className="hrv-report-col-12">
              <ReportHeader
                isAccessReportDiscount={info.reportNameSystem === "sales_promotion"}
                isSystemReport={info.isSystemReport}
                type={name}
                reportName={reportName}
                onClickRefresh={() => {
                  doRefresh();
                }}
                onClickDelete={() => {
                  handleOpenModal("delete");
                }}
                onClickDuplicate={() => {
                  handleOpenModal("duplicate");
                }}
                onHandleOnClickAbout={handleOnClickAbout}
                reportNameSystem={info.reportNameSystem}
                typeFilterFromListing={typeFilterFromListing}
                isFavoriteFromListing={isFavoriteFromListing}
                searchKeyFromListing={searchKeyFromListing}
                historyPath={historyPath}
                authors={authors}
                isFavourite={isFavourite}
                exportId={exportId}
                onClickExport={handleOpenModal}
                handlePrintApi={handlePrintApi}
                dataPrint={dataPrint}
                isLoadingPrint={isLoadingPrint}
                canReadMACost={canReadMACost}
                headers={statistical_table_data.headerreport}
                sortValue={sortValue}
                groupBySelected={groupBySelected}
                dataLink={statistical_table_data.datalink}
                optionsFilter={filter}
                currentFilter={filterSelected}
                reportId={reportId}
                isAddNewReport={info.isAddNewReport}
              />

              <BoilerTemplate
                isAccessReportDiscount={info.reportNameSystem === "sales_promotion"}
                reportNameSystem={info.reportNameSystem}
                canReadMACost={canReadMACost}
                headers={statistical_table_data.headerreport}
                data={statistical_table_data.data}
                dataLink={statistical_table_data.datalink}
                summary={dataSummary || []}
                onChangeDate={onChangeDate}
                dateStart={startdate}
                dateEnd={enddate}
                isLoadingTable={isLoadingTable}
                groupBy={groupBy}
                groupAreas={groupAreas}
                onHandleCheckboxChart={handleChangeCheckboxChart}
                measure={measure}
                groupBySelected={groupBySelected}
                measureSelected={measureSelected}
                sortValue={sortValue}
                onHandleSortTable={handleSortTable}
                optionDimension={filter}
                filterTag={filterSelected}
                onHandleClose={handleCloseFilter}
                displayChartMode={info.displayChartMode}
                onChangePage={handleChangePage}
                handleChangeGroupBy={handleChangeGroupBy}
                handleChangeColumnShowModal={handleChangeColumnShowModal}
                isHiddenPagination={isHiddenPagination}
                compareDateStart={compareDateStart}
                compareDateEnd={compareDateEnd}
                onChangeCompareDate={onChangeCompareDate}
                optionMeasure={optionMeasure}
                onChangeYAxis={handleChangeYAxis}
                discount={discount}
                autoCompleteFilterPanel={autoCompleteFilterPanel}
                isLoadingAutoCompleteFilterPanel={
                  isLoadingAutoCompleteFilterPanel
                }
                onHandleChangeFilter={handleChangeFilter}
                benchmark={benchmark}
                optionsHeatMap={heatmapOptions}
                onChangeHeatmaps={handleChangeHeatmap}
                onHandleChangeCheckboxInFilterTypeSingle={
                  handleChangeCheckboxInFilterTypeSingle
                }
                groupByFilterPanel={groupByFilterPanel}
                onHandleSubmitFilterWithDimension={handleSubmitFilterWithDimension}
                reportId={id}
                reportName={reportName}
                exportId={exportId}
                optionCompare={optionCompare}
                submitFilterV2={handleSubmitFilterV2}
                dataExport={dataExport}
                onHandleTriggerGroupByAndFilter={handleTriggerGroupByAndFilter}
                dependdata={statistical_table_data.dependdata}
                dataFetchingTable={dataFetchingTable}
                onHandleCloseFilterByDimension={handleCloseFilterByDimension}
                measureDraw={measureDraw}
                // * Gọi promotion v1 (Tương lai sẽ remove ra)
                query={current}
                queryPeriod={previous}
                isLoadingChart={isLoading}
                onHandleChangeChartType={handleChangeChartType}
              />

              <ModalReportDetail
                type={modalType}
                onClickClose={() => {
                  dispatch(actions.setModalType(""))
                }}
                onClickApply={handleApplyInModal}
              />
            </div>
          </div>

          {!isLoadingLastestView && <Rating
            type="emotion"
            reportId={id}
            reportName={info.reportName}
            visible={dayjs().diff(dayjs(dataLastestView.createdDate), 'days') >= 14 && !info.isFeedback}
          />
          }
          <BackToTop />

        </div>
      )}
    </>
  );
};

export default memo(Main);

const svgCheckedIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.99984 1.6665C5.39984 1.6665 1.6665 5.39984 1.6665 9.99984C1.6665 14.5998 5.39984 18.3332 9.99984 18.3332C14.5998 18.3332 18.3332 14.5998 18.3332 9.99984C18.3332 5.39984 14.5998 1.6665 9.99984 1.6665ZM8.33317 14.1665L4.1665 9.99984L5.3415 8.82484L8.33317 11.8082L14.6582 5.48317L15.8332 6.6665L8.33317 14.1665Z" fill="white" />
</svg>