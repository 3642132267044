import { useState } from 'react';

import { ShowUIDate } from 'helpers/helpers';
import dayjs from "dayjs";

import DateRangePicker from './components';
import useTranslate from 'hooks/useTranslate';
import { useStickyCalendar } from 'hooks/useStickyCalendar';

function DatePickerDemoPage({
    onChangeDate,
    dateStart = dayjs(),
    dateEnd = dayjs(),
    isShowIcon = true,
    customText,
    isComparePeriod = false,
    placement = "right",
    maxDate,
    isYearPicker,
    fullWidth,
    customContent,
    isStockLot = false,
    isRFM = false,
    size = 'm',
    threshold = 0,
    alwaysShowCalendars = true,
    ...restProps }) {
    const [start, setStart] = useState(dayjs().clone().subtract(100, "days"));
    const [end, setEnd] = useState(dayjs());
    const { getLocaleDatePicker } = useTranslate();
    const locale = getLocaleDatePicker();
    const [isShowCalendar, setIsShowCalendar] = useState(false);
    useStickyCalendar({ id: "demo-calendar", threshold })
    const rangeName =
        dateStart &&
            end ? ShowUIDate(start, end, isStockLot, isRFM) : "";


    const handleDateRangeChange = ({ startDate, endDate }) => {
        setStart(startDate)
        setEnd(endDate)
    };

    const handleShow = () => {
        setIsShowCalendar(true)
    }

    const handleHide = () => {
        setIsShowCalendar(false)
    }
    return (
        <div>
            <DateRangePicker
                locale={locale}
                startDateDefault={start}
                endDateDefault={end}
                onChange={handleDateRangeChange}
                minYear={2014}
                maxYear={parseInt(dayjs().format('YYYY'))}
                minDate={dayjs().year(2014).startOf("year")}
                maxDate={dayjs().endOf("year")}
                singleDatePicker={false}
                onHide={handleHide}
                onShow={handleShow}
                threshold={8}
                ranges={locale.ranges}
            >
                <div className={`
                hrv-report-daterangepicker hrv-report-d-flex hrv-report-justify-between hrv-report-items-center
        hrv-report-custom_datepicker ${isShowCalendar ? "hrv-report-custom_datepicker__active" : ""
                    }
                    `}>
                    <span className="">
                        <span className="hrv-report-mr-8 hrv-report-text-color-gray-12 hrv-report-fs-14">
                            {isShowIcon ? calendarSvg : customText}
                        </span>

                        <span className="hrv-report-mr-8 hrv-report-fs-14">
                            {rangeName}
                        </span>
                    </span>
                </div>

            </DateRangePicker>
        </div>
    )
}

export default DatePickerDemoPage;


const calendarSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8333 3.33329H15V1.66663H13.3333V3.33329H6.66667V1.66663H5V3.33329H4.16667C3.24167 3.33329 2.50833 4.08329 2.50833 4.99996L2.5 16.6666C2.5 17.5833 3.24167 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6666V4.99996C17.5 4.08329 16.75 3.33329 15.8333 3.33329ZM15.8333 16.6666H4.16667V8.33329H15.8333V16.6666ZM15.8333 6.66663H4.16667V4.99996H15.8333V6.66663ZM7.5 11.6666H5.83333V9.99996H7.5V11.6666ZM10.8333 11.6666H9.16667V9.99996H10.8333V11.6666ZM14.1667 11.6666H12.5V9.99996H14.1667V11.6666ZM7.5 15H5.83333V13.3333H7.5V15ZM10.8333 15H9.16667V13.3333H10.8333V15ZM14.1667 15H12.5V13.3333H14.1667V15Z" fill="#4B5563" />
    </svg>
);